import "./login.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { SITE_KEY } from '../../../constants'

//Components
import { FaIdCard, FaSignInAlt } from "react-icons/fa"
import AdminService from "../../../services/admin_services"
import token_services from "../../../services/token_services"

class AdminLogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: null,
            email: null,
            password: null,
            twoFA: null,
            display: false,
        }
    }

    componentDidMount = async() => {
        const jwt = token_services.getLocalAccessToken()
        if(jwt)
            window.location.href="/admin"
    }

    onVerifyCaptcha = (token) => {
        this.setState({ token })
    }

    login = async () => {
        if (!this.state.email || !this.state.password)
            NotificationManager.error('Missing credentials')
        else if (!this.state.token)
            this.setState({ error: 'reCaptcha missing', info: null, success: false })
        else {
            try {
                await AdminService.login(this.state.email, this.state.password, this.state.token)
                this.setState({display: true})
            } catch (e) {
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    login2fa = async () => {
        if (!this.state.email || !this.state.password)
            NotificationManager.error('Missing credentials')
        else if (!this.state.twoFA)
            this.setState({ error: '2FA missing', info: null, success: false })
        else {
            try {
                await AdminService.login2fa(this.state.email, this.state.password, this.state.twoFA)
                window.location.href = "/admin"
            } catch (e) {
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <div className="container_admin">
                <div className="main mt-50">
                    <div className="login">
                        <h2>Admin access</h2>
                        <hr />
                        <p>Sign in to access administration utils</p>
                        <input type="email" placeholder="Email" onChange={(e) => this.setState({ email: e.target.value })} />
                        <input type="password" placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} />
                        <div className="hcaptcha">
                            <HCaptcha
                                sitekey={SITE_KEY}
                                onVerify={this.onVerifyCaptcha}
                            />
                        </div>
                        {!this.state.display && <button onClick={this.login}>Request 2FA <FaIdCard /></button>}
                        {this.state.display && <input type="number" placeholder="2FA Email" onChange={(e) => this.setState({ twoFA: e.target.value })} />}
                        {this.state.display && <button onClick={this.login2fa}>Sign In <FaSignInAlt /></button>}
                    </div>
                </div>
                <NotificationContainer />
                
            </div>
        )
    }
}

export default withTranslation()(AdminLogin)