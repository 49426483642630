import './fonts/Poppins-Black.ttf';
import './fonts/Poppins-BlackItalic.ttf';
import './fonts/Poppins-Bold.ttf';
import './fonts/Poppins-BoldItalic.ttf';
import './fonts/Poppins-ExtraBold.ttf';
import './fonts/Poppins-ExtraBoldItalic.ttf';
import './fonts/Poppins-ExtraLight.ttf';
import './fonts/Poppins-ExtraLightItalic.ttf';
import './fonts/Poppins-Italic.ttf';
import './fonts/Poppins-Light.ttf';
import './fonts/Poppins-LightItalic.ttf';
import './fonts/Poppins-Medium.ttf';
import './fonts/Poppins-MediumItalic.ttf';
import './fonts/Poppins-Regular.ttf';
import './fonts/Poppins-SemiBold.ttf';
import './fonts/Poppins-SemiBoldItalic.ttf';
import './fonts/Poppins-Thin.ttf';
import './fonts/Poppins-ThinItalic.ttf';
import './fonts/Qwigley-Regular.ttf';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './web.css';
import "./i18nextInit";
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();