import './admin.css'
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

//Components
import TokenService from "../../services/token_services"

class AdminHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            account: {}
        }
    }

    componentDidMount = async () => {
        const user = await TokenService.getUser()
        this.setState({ account: user ? user.account : {} })
    }

    logOut = () => {
        TokenService.removeUser()
        window.location.href = '/admin/login'
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2>
                        <span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span>
                        <button className="button" style={{ float: 'right' }} onClick={this.logOut}>Log out</button>
                    </h2>
                    <div className="selectcard mt-0">
                        {this.state.account &&
                            <h4 className="left mb-0">
                                hello {this.state.account.firstname} {this.state.account.lastname}
                            </h4>}
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/buds'}>
                            <div className="entry_name">
                                Buds
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/players'}>
                            <div className="entry_name">
                                Players
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/business'}>
                            <div className="entry_name">
                                Business
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/stats'}>
                            <div className="entry_name">
                                Statistics
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/accounts'}>
                            <div className="entry_name">
                                Admin accounts
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/bots'}>
                            <div className="entry_name">
                                Bots
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/subscribers'}>
                            <div className="entry_name">
                                Subscribers
                            </div>
                        </div>
                        <div className="menu_entry" onClick={() => window.location.href = '/admin/trackers'}>
                            <div className="entry_name">
                                Trackers
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(AdminHome)