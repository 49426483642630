import api from './api';

class PlayerService {
  async startDeck(bud_uuid) {
    return await api
      .post('/player/deck/start', {bud_uuid})
      .then(async (response) => {
        return response.data;
      });
  }

  async getDeck() {
    return await api
      .get('/player/deck')
      .then(async (response) => {
        return response.data.cards;
      });
  }

  async getActiveDeck() {
    return await api
      .get('/player/deck?selected=true')
      .then(async (response) => {
        return response.data.cards;
      });
  }

  async getProfile() {
    return await api
      .get('/player/profile')
      .then(async (response) => {
        return response.data;
      });
  }

  async getPublicProfile(player_uuid) {
    return await api
      .get(`/player/profile/public/${player_uuid}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async update(profile) {
    return api.post('/player/profile', profile).then((response) => {
      if (response.data.status) return this.getProfile();
      else return response.data;
    });
  }

  async safeStart(safe_uuid) {
    return api.post('/player/safe/start', {safe_uuid}).then((response) => {
      return response.data;
    });
  }

  async safeRemove(safe_uuid) {
    return api.get(`/player/safe/remove/${safe_uuid}`).then((response) => {
      return response.data;
    });
  }

  async safeOpen(safe_uuid) {
    return api.get(`/player/safe/open/${safe_uuid}`).then((response) => {
      return response.data;
    });
  }

  async getRanking() {
    return api.get(`/player/ranking`).then((response) => {
      return response.data;
    });
  }

  async getSlotPrices() {
    return api.get('/player/safe/slot/price').then((response) => {
      return response.data;
    });
  }

  async getNewSlot() {
    return api.get('/player/safe/slot').then((response) => {
      return response.data;
    });
  }

  async getSubscribers() {
    return api.get('/player/subscriber').then((response) => {
      return response.data.subscribers;
    });
  }

  async notifySubscribers(subscribers_uuid) {
    return await api
      .post(`/player/subscriber/notify`, {subscribers_uuid})
      .then(async (response) => {
        return response.data;
      });
  } 

  async unsubUser(subscribers_uuid) {
    return await api
      .post(`/player/unsubscribe/confirm`, {subscribers_uuid})
      .then(async (response) => {
        return response.data;
      });
  } 

  async unsubscribe(subscriber_uuid) {
    return api.post('/player/unsubscribe', {subscriber_uuid}).then((response) => {
      return response.data.status;
    });
  }

  async searchPlayers(username, email_set, notifications, deactivated=false) {
    return await api
      .post(`/player/search?deactivated=${deactivated}`, {username, email_set, notifications})
      .then(async (response) => {
        return response.data.players;
      });
  } 

  async sendPlayerStars(player_uuid, nb_stars, reason, subject, message) {
    return await api
      .post(`/player/gift`, {player_uuid, nb_stars, reason, subject, message})
      .then(async (response) => {
        return response.data;
      });
  } 

}
// eslint-disable-next-line
export default new PlayerService();
