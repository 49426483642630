import "./accounts.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'

//Components
import Footer from '../../../components/footer'
import AdminService from "../../../services/admin_services"

class AdminCreateAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            levels: [],
            level: null,
            firstname: null,
            lastname: null,
            email_address: null,
            password: null,
        }
    }

    create = async () => {
        if (!this.state.firstname || !this.state.lastname || !this.state.email_address || !this.state.password)
            NotificationManager.error('Missing information')
        else if (this.state.password.length < 8)
            NotificationManager.error('Password length must be at least 8 characters')
        else {
            try {
                this.setState({ processing: true })
                const data = await AdminService.createAdmin(this.state.password, this.state.email_address, this.state.firstname, this.state.lastname)
                NotificationManager.success(data.message)
                this.setState({ processing: false, success: true })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/accounts'}>Accounts</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">Set all required information to create a new Administration account</h4>
                        <input type="text" placeholder="Firstname" onChange={(e) => this.setState({ firstname: e.target.value })} />
                        <input type="text" placeholder="Lastname" onChange={(e) => this.setState({ lastname: e.target.value })} />
                        <input type="email" placeholder="Email" onChange={(e) => this.setState({ email_address: e.target.value })} />
                        <input type="password" placeholder="Password" onChange={(e) => this.setState({ password: e.target.value })} />
                        {this.state.processing?
                            <p>Processing... Please wait</p>
                        :
                            !this.state.success? 
                                <button className="button" onClick={this.create}>Create account</button>
                            :
                                <button className="button" onClick={() => window.location.href="/admin/accounts"}>🡐 Back</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withTranslation()(AdminCreateAccount)