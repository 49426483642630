import "./bots.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'

//Components
import AdminService from "../../../services/admin_services"

class AdminBots extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bots: [],
            bot_level: 100,
            username: null,
            processing: false,
        }
    }

    componentDidMount = async () => {
        await this.loadBots()
    }

    loadBots = async () => {
        const bots = await AdminService.getBots()
        this.setState({ bots })
    }

    updateCard = async (card_uuid, scores) => {
        await AdminService.updateBotsCard(card_uuid, scores)
        NotificationManager.success('Card updated')
        this.loadBots()
    }

    updateCardXP = async (card_uuid, new_xp) => {
        await AdminService.updateBotsXP(card_uuid, new_xp)
        NotificationManager.success('Card XP updated')
        this.loadBots()
    }

    createNewBot = async () => {
        if (window.confirm("Do you confirm bot creation?")) {
            this.setState({ processing: true })
            await AdminService.createBot(this.state.bot_level, this.state.username)
            NotificationManager.success('Bot created')
            await this.loadBots()
            this.setState({ processing: false })
        }
    }

    restartBots = async () => {
        if (window.confirm("Do you confirm bot restart?")) {
            this.setState({ processing: true })
            await AdminService.resetBots()
            NotificationManager.success('Bots restarted')
            await this.loadBots()
            this.setState({ processing: false })
        }
    }

    updatePWR = async (player_uuid) => {
        this.setState({ processing: true })
        await AdminService.updateBotPower(player_uuid)
        NotificationManager.success('Bot PWR updated')
        await this.loadBots()
        this.setState({ processing: false })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/bots'}>Bots</span></h2>
                    <div className="selectcard mt-0">
                        <div style={{ maxWidth: "200px" }}>
                            <small>Bot level</small>
                            <input type="number" placeholder="Bot level" value={this.state.bot_level} style={{ margin: "2px 0" }} onChange={(e) => this.setState({ bot_level: e.target.value > 0 ? e.target.value : 1 })} />
                            <br />
                            <small>Bot username</small>
                            <input type="text" placeholder="Username" value={this.state.username} style={{ margin: "2px 0" }} onChange={(e) => this.setState({ username: e.target.value })} />
                            {!this.state.processing ?
                                <>
                                    <button className="button d-line" onClick={this.createNewBot}>🤖 New bot</button>
                                    <button className="button d-line" onClick={this.restartBots}>⟳ Restart bots</button>
                                </>
                                :
                                <small>Processing...</small>
                            }
                        </div>
                        <h4 className="left mb-0">
                            Browse available bots
                        </h4>
                        {this.state.bots.map(bot =>
                            <div key={bot.player_uuid}>
                                <h4 className="left mt-20 mb-0">{bot.username} <small>[{bot.trophies} LVL] - [{bot.deck_power} PWR]</small> {bot.playing && <small style={{ color: "#00AA00" }}>[PLAYING]</small>} {bot.disappeared && <small style={{ color: "#FF0000" }}>[DISAPPEARED]</small>}</h4>
                                <button className="button d-line" onClick={() => this.updatePWR(bot.player_uuid)}>Update PWR</button>
                                <div className="selecter mt-0">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="left" style={{ width: "40px" }}>#</th>
                                                <th className="filter left" style={{ width: "140px" }}>Power</th>
                                                <th className="filter left" style={{ width: "100px" }}>Top</th>
                                                <th className="filter left" style={{ width: "100px" }}>Right</th>
                                                <th className="filter left" style={{ width: "100px" }}>Bottom</th>
                                                <th className="filter left" style={{ width: "100px" }}>Left</th>
                                                <th className="filter left" style={{ width: "60px" }}>XP</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bot.cards.map((card, index) =>
                                                <tr key={`bud_${card.card_uuid}`}>
                                                    <td>
                                                        <strong>{index + 1}</strong>
                                                    </td>
                                                    <td>
                                                        <strong>{card.power}</strong>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {card.scores[0]}
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0] - 1, card.scores[1], card.scores[2], card.scores[3]])}> - </strong>
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0] + 1, card.scores[1], card.scores[2], card.scores[3]])}> + </strong>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {card.scores[1]}
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0], card.scores[1] - 1, card.scores[2], card.scores[3]])}> - </strong>
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0], card.scores[1] + 1, card.scores[2], card.scores[3]])}> + </strong>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {card.scores[2]}
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0], card.scores[1], card.scores[2] - 1, card.scores[3]])}> - </strong>
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0], card.scores[1], card.scores[2] + 1, card.scores[3]])}> + </strong>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {card.scores[3]}
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0], card.scores[1], card.scores[2], card.scores[3] - 1])}> - </strong>
                                                            <strong className="cursor ml-10 pointer" onClick={() => this.updateCard(card.card_uuid, [card.scores[0], card.scores[1], card.scores[2], card.scores[3] + 1])}> + </strong>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {card.xp}
                                                            {card.xp > 1 && <strong className="cursor ml-10 pointer" onClick={() => this.updateCardXP(card.card_uuid, (card.xp - 1))}> - </strong>}
                                                            {card.xp < 99 && <strong className="cursor ml-10 pointer" onClick={() => this.updateCardXP(card.card_uuid, (card.xp + 1))}> + </strong>}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>)}
                    </div>
                </div>
                <NotificationContainer />

            </>
        )
    }
}

export default withTranslation()(AdminBots)