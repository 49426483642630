import "./business.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { FaCheck, FaTimes } from "react-icons/fa"

//Components
import businesseservice from "../../../services/business_services"

class AdminBusinessActivities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activities: [],
            deactivated: false,
            selectKey: null,
            order: 'asc',
            loading: true,
            processing: false,
            name_en: null,
            name_fr: null,
            desc_en: null,
            desc_fr: null,
        }
    }

    componentDidMount = async () => {
        await this.loadActivities()
    }

    loadActivities = async () => {
        this.setState({ loading: true })
        const activities = await businesseservice.businessActivities(this.state.deactivated)
        this.setState({ activities, loading: false })
    }

    switchFilter = async () => {
        this.setState({ deactivated: !this.state.deactivated }, () => this.loadActivities())
    }

    reorder = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let activities = this.state.activities
        if (order === 'asc')
            activities.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            activities.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ activities })
    }

    activate = async (business_activity_uuid) => {
        try {
            const data = await businesseservice.activateActivity(business_activity_uuid)
            NotificationManager.success(data.message)
            this.loadActivities()
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    deactivate = async (business_activity_uuid) => {
        try {
            const data = await businesseservice.deactivateActivity(business_activity_uuid)
            NotificationManager.success(data.message)
            this.loadActivities()
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    create = async () => {
        if (!this.state.name_en || !this.state.name_fr)
            NotificationManager.error('Missing information')
        else {
            try {
                this.setState({ processing: true })
                const data = await businesseservice.newBusinessActivity(this.state.name_en, this.state.name_fr, this.state.desc_en, this.state.desc_fr)
                NotificationManager.success(data.message)
                this.loadActivities()
                this.setState({ processing: false, name_en: null, name_fr: null, desc_fr: null, desc_en: null  })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/business'}>Business</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/business/activities'}>Activities</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">
                            Browse business activities
                            {this.state.deactivated ?
                                <span className="switch" onClick={this.switchFilter}>Display active activities</span>
                                :
                                <span className="switch" onClick={this.switchFilter}>Display deactivated activities</span>
                            }
                        </h4>
                        {this.state.loading ?
                            <p>Loading...</p>
                            :
                            <div className="selecter">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="left" style={{ width: "40px" }}>#</th>
                                            <th className="filter left" style={{ width: "120px" }} onClick={() => this.reorder("business_name")}>⥃ Name EN</th>
                                            <th className="filter left" style={{ width: "120px" }} onClick={() => this.reorder("website")}>⥃ Name FR</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("city")}>⥃ Desc EN</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("country")}>⥃ Desc FR</th>
                                            <th className="filter left" style={{ width: "180px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.activities.map((activity, index) =>
                                            <tr key={`activity_${activity.business_activity_uuid}`}>
                                                <td>
                                                    <strong>{index + 1}</strong>
                                                </td>
                                                <td>
                                                    <span className="left">{activity.activity_name?.en}</span>
                                                </td>
                                                <td>
                                                    <span className="left">{activity.activity_name?.fr}</span>
                                                </td>
                                                <td>
                                                    <small className="left">{activity.description?.en}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{activity.description?.fr}</small>
                                                </td>
                                                <td>
                                                    {this.state.deactivated ?
                                                        <div className="line_deactivate" onClick={() => this.activate(activity.business_activity_uuid)}> <FaCheck /> </div>
                                                        :
                                                        <div className="line_deactivate" onClick={() => this.deactivate(activity.business_activity_uuid)}> <FaTimes /> </div>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                        <div className="selectcard mt-0">
                            <h4 className="left mb-0">New business activity</h4>
                            <input type="text" placeholder="Name EN" value={this.state.name_en} onChange={(e) => this.setState({ name_en: e.target.value })} />
                            <input type="text" placeholder="Name FR" value={this.state.name_fr} onChange={(e) => this.setState({ name_fr: e.target.value })} />
                            <input type="text" placeholder="Description EN" value={this.state.desc_en} onChange={(e) => this.setState({ desc_en: e.target.value })} />
                            <input type="text" placeholder="Description FR" value={this.state.desc_fr} onChange={(e) => this.setState({ desc_fr: e.target.value })} />
                            {this.state.processing ?
                                <p>Processing... Please wait</p>
                                :
                                <button className="button" onClick={this.create}>Create activity</button>
                            }
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </>
        )
    }
}

export default withTranslation()(AdminBusinessActivities)