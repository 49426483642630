import "../buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'

//Components
import Footer from '../../../../components/footer'
import CardService from "../../../../services/card_services"

class AdminCreateCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            name: null,
            description_fr: null,
            description_en: null,
        }
    }

    create = async () => {
        if (!this.state.name || !this.state.description_fr || !this.state.description_en)
            NotificationManager.error('Missing information')
        else {
            try {
                this.setState({processing: true})
                const data = await CardService.createCollection(this.state.name, {en: this.state.description_en, fr: this.state.description_fr})
                NotificationManager.success(data.message)
                this.setState({processing: false, success: true})
            } catch (e) {
                this.setState({processing: false})
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">Set all required information to create a new Collection</h4>
                        <input type="text" placeholder="Set Collection name" onChange={(e) => this.setState({name: e.target.value})}/>
                        <textarea  placeholder="Description EN" onChange={(e) => this.setState({description_en: e.target.value})}></textarea>
                        <textarea  placeholder="Description FR" onChange={(e) => this.setState({description_fr: e.target.value})}></textarea>
                        {this.state.processing?
                            <p>Processing... Please wait</p>
                        :
                            !this.state.success? 
                                <button className="button" onClick={this.create}>Create new collection</button>
                            :
                                <button className="button" onClick={() => window.location.href="/admin/buds"}>🡐 Back</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withTranslation()(AdminCreateCollection)