import "./subscribers.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { FaAndroid, FaApple } from "react-icons/fa"

//Components
import Footer from '../../../components/footer'
import player_services from "../../../services/player_services"

class AdminSubscribers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            subscribers: [],
            deactivated: false,
            selectKey: null,
            order: 'asc',
            selected: [],
            processing: false,
        }
    }

    componentDidMount = async () => {
        await this.loadSubscribers()
    }

    loadSubscribers = async () => {
        const subscribers = await player_services.getSubscribers()
        this.setState({ subscribers })
    }

    reorder = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let subscribers = this.state.subscribers
        if (order === 'asc')
            subscribers.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            subscribers.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ subscribers })
    }

    selectAll = () => {
        if (this.state.selected.length === this.state.subscribers.length) {
            this.setState({ selected: [] })
        } else {
            let selected = []
            for (let sub of this.state.subscribers) {
                selected.push(sub.subscriber_uuid)
            }
            this.setState({ selected })
        }
    }

    selectSub = (sub) => {
        if (this.state.selected.includes(sub)) {
            let selected = this.state.selected
            const index = selected.indexOf(sub);
            selected.splice(index, 1);
            this.setState({ selected })
        } else
            this.setState({
                selected: [...this.state.selected, sub]
            })
    }

    notifySubs = async () => {
        if (this.state.selected.length === 0)
            NotificationManager.error('Select at least one subscriber')
        else {
            try {
                this.setState({ processing: true })
                const data = await player_services.notifySubscribers(this.state.selected)
                NotificationManager.success(data.message)
                this.loadSubscribers()
                this.setState({ processing: false })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    unsub = async () => {
        if (this.state.selected.length === 0)
            NotificationManager.error('Select at least one subscriber')
        else {
            try {
                this.setState({ processing: true })
                const data = await player_services.unsubUser(this.state.selected)
                NotificationManager.success(data.message)
                this.loadSubscribers()
                this.reorder(this.state.selectKey)
                this.setState({ processing: false })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/subscribers'}>Subscribers</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">
                            Browse registered subscribers
                        </h4>
                        {this.state.processing ?
                            <p>Processing... please wait</p>
                            :
                            <div className="selecter">
                                <button className="button" style={{ display: "inline-block", marginRight: "20px" }} onClick={this.notifySubs}>Notify subscribers</button>
                                <button className="button" style={{ display: "inline-block", marginRight: "20px" }} onClick={this.unsub}>Confirm unsubscriptions</button>
                                <table className="mt-20">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "40px" }}><input type="checkbox" className="checkbox" onClick={this.selectAll} checked={this.state.selected.length === this.state.subscribers.length} /></th>
                                            <th className="left" style={{ width: "40px" }}>#</th>
                                            <th className="filter left" style={{ width: "220px" }} onClick={() => this.reorder("email")}>⥃ Email</th>
                                            <th className="filter left" style={{ width: "220px" }} onClick={() => this.reorder("created_date")}>⥃ Registration date</th>
                                            <th className="filter left" style={{ width: "120px" }} onClick={() => this.reorder("granted")}>⥃ Access grant</th>
                                            <th className="filter left" style={{ width: "120px" }} onClick={() => this.reorder("mobile_os")}>⥃ Mobile OS</th>
                                            <th className="filter left" style={{ width: "120px" }} onClick={() => this.reorder("mobile_os")}>⥃ Unsubscribe</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.subscribers.map((subscriber, index) =>
                                            <tr key={`business_${subscriber.subscriber_uuid}`} style={{ height: "60px" }}>
                                                <td>
                                                    <input type="checkbox" className="checkbox" onClick={() => this.selectSub(subscriber.subscriber_uuid)} checked={this.state.selected.includes(subscriber.subscriber_uuid)} />
                                                </td>
                                                <td>
                                                    <strong>{index + 1}</strong>
                                                </td>
                                                <td>
                                                    <strong className="left ml-10">{subscriber.email}</strong>
                                                </td>
                                                <td>
                                                    <small className="left">{subscriber.created_date.substring(0, 19).replace("T", " ")}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{subscriber.granted ? "GRANTED" : "AWAITING"}</small>
                                                </td>
                                                <td>
                                                    {subscriber.mobile_os === "ios" && <span className="center"><FaApple /></span>}
                                                    {subscriber.mobile_os === "android" && <span className="center"><FaAndroid /></span>}
                                                </td>
                                                <td>
                                                    {subscriber.unsubscribed ?
                                                        subscriber.deactivated ?
                                                            <small className="left">DELETED</small>
                                                            :
                                                            <small className="left">REQUESTED</small>
                                                        :
                                                        ""
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withTranslation()(AdminSubscribers)