import "./stats.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Chart from "react-apexcharts"

//Components
import AdminService from "../../../services/admin_services"

const d = new Date();


class AdminStatSafes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            safes_daily_stats: {},
            safes_stats: {},
            end_date: new Date().toISOString().substring(0, 10),
            start_date: new Date(d.setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10),
            options_safes: null,
            series_star: null,
        }
    }

    componentDidMount = async () => {
        await this.loadStats()
    }

    loadStats = async () => {
        const safes = await AdminService.getSafesUsage(this.state.start_date, this.state.end_date)
        const nb_deleted = [], nb_opened = [], nb_safes = [], nb_stars = []
        Object.keys(safes.daily_stats).map(key => nb_deleted.push(safes.daily_stats[key].nb_deleted))
        Object.keys(safes.daily_stats).map(key => nb_opened.push(safes.daily_stats[key].nb_opened))
        Object.keys(safes.daily_stats).map(key => nb_safes.push(safes.daily_stats[key].nb_safes))
        Object.keys(safes.daily_stats).map(key => nb_stars.push(safes.daily_stats[key].nb_stars))
        this.setState({
            safes_daily_stats: safes.daily_stats,
            safes_stats: safes.safes_stats,
            options_safes: { chart: { id: "safes-bar" }, xaxis: { categories: Object.keys(safes.daily_stats) } },
            nb_deleted: [{ name: "safes", data: nb_deleted }],
            nb_opened: [{ name: "safes", data: nb_opened }],
            nb_safes: [{ name: "safes", data: nb_safes }],
            nb_stars: [{ name: "safes", data: nb_stars }]
        })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats'}>Statistics</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats/safes'}>Safes</span></h2>
                    <div className="selectcard mt-0">
                        <h2 className="left mb-0">
                            🔐 Safes statistics
                        </h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5>Start date: <input type="date" defaultValue={this.state.start_date} onChange={(e) => this.setState({ start_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                    <td>
                                        <h5>End date: <input type="date" defaultValue={this.state.end_date} onChange={(e) => this.setState({ end_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellPadding={5} border={1} width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <th>
                                        Delay by safe
                                    </th>
                                    <th>
                                        Delay by star
                                    </th>
                                    <th>
                                        Deleted rate
                                    </th>
                                    <th>
                                        Nb safes
                                    </th>
                                    <th>
                                        Safes opened by day
                                    </th>
                                    <th>
                                        Safes win by day
                                    </th>
                                    <th>
                                        Stars by safe
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <h5>{this.state.safes_stats.delay_by_safe}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.safes_stats.delay_by_star}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.safes_stats.deleted_rate}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.safes_stats.nb_safes}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.safes_stats.safes_opened_by_day}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.safes_stats.safes_win_by_day}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.safes_stats.stars_by_safe}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>Deleted</h5>
                        {this.state.nb_deleted && <Chart
                            options={this.state.options_safes}
                            series={this.state.nb_deleted}
                            type="bar"
                        />}
                        <h5>Opened</h5>
                        {this.state.nb_opened && <Chart
                            options={this.state.options_safes}
                            series={this.state.nb_opened}
                            type="bar"
                        />}
                        <h5>Nb safes</h5>
                        {this.state.nb_safes && <Chart
                            options={this.state.options_safes}
                            series={this.state.nb_safes}
                            type="bar"
                        />}
                        <h5>Nb stars</h5>
                        {this.state.nb_stars && <Chart
                            options={this.state.options_safes}
                            series={this.state.nb_stars}
                            type="bar"
                        />}
                    </div>
                </div>
                
            </>
        )
    }
}

export default withTranslation()(AdminStatSafes)