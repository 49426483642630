import "../buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'

//Components
import CardService from "../../../../services/card_services"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}


class AdminNewBudSale extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            levels: [],
            bud_uuid: null,
            level: null,
            bud_name: null,
            description: '',
            img_url: null,
            sale_info: null,
            price: null,
            sale_status: "true",
            start_date: null,
            end_date: null,
            max_sales: null,

        }
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const buds = await CardService.getBuds()
        for (let bud of buds) {
            if (bud.bud_uuid === id) {
                this.setState({
                    level: bud.bud_level,
                    bud_name: bud.bud_name,
                    description: bud.description,
                    img_url: bud.img_url,
                    bud_uuid: id,
                    sale_info: bud.sale_info,
                    xp: 1,
                })
            }

        }
        const levels = await CardService.getLevels()
        this.setState({ levels })
    }

    newSale = async () => {
        if (!this.state.price || !this.state.end_date)
            NotificationManager.error('Missing information')
        else {
            try {
                this.setState({ processing: true })
                const data = await CardService.newBudSale(this.state.bud_uuid, this.state.price, this.state.max_sales,
                    this.state.start_date ? new Date(this.state.start_date).toISOString() : null, new Date(this.state.end_date).toISOString(),
                    this.state.sale_status, this.state.xp)
                NotificationManager.success(data.message)
                this.setState({ processing: false, success: true })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0" style={{ paddingBottom: "20px" }} >
                        <h4 className="left mb-0">New Bud sale</h4>
                        {this.state.img_url && <img src={this.state.img_url} alt="Bud visual" className="bud_visual" />}
                        <h2>{this.state.bud_name}
                            <div className={`lvl ${this.state.level}`}>{this.state.level}</div></h2>
                        {this.state.description.en && <p>EN: {this.state.description.en}</p>}
                        {this.state.description.fr && <p>FR: {this.state.description.fr}</p>}
                        {!this.state.description.en && <p>OLD FORMAT: {this.state.description}</p>}
                        <hr />
                        <h2>Set new Sale</h2>
                        <p className="mb-0">★ Sale price</p>
                        <input type="number" placeholder="Set sale price" onChange={(e) => this.setState({ price: e.target.value })} value={this.state.price} />
                        <p className="mb-0">Sale status {this.state.sale_status}</p>
                        <select onChange={(e) => this.setState({ sale_status: e.target.value })}>
                            <option selected={this.state.sale_status} value={"true"}>Active</option>
                            <option selected={!this.state.sale_status} value={"false"}>Not active</option>
                        </select>
                        <p className="mb-0">Start date</p>
                        <input type="datetime-local" placeholder="Set start date" onChange={(e) => this.setState({ start_date: e.target.value })} value={this.state.start_date} />
                        <p className="mb-0">End date</p>
                        <input type="datetime-local" placeholder="Set end date" onChange={(e) => this.setState({ end_date: e.target.value })} value={this.state.end_date} />
                        <p className="mb-0">Max sales</p>
                        <input type="text" placeholder="Set max sales" onChange={(e) => this.setState({ max_sales: e.target.value })} value={this.state.max_sales} />
                        <p className="mb-0">XP</p>
                        <input type="number" placeholder="Set XP" onChange={(e) => this.setState({ xp: e.target.value })} value={this.state.xp} />
                        {this.state.processing ?
                            <p>Processing... Please wait</p>
                            :
                            !this.state.success ?
                                <button className="button" onClick={this.newSale}>Set sale</button>
                                :
                                <button className="button" onClick={() => window.location.href = `/admin/buds/details/${this.state.bud_uuid}`}>🡐 Back</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withRouter(AdminNewBudSale)