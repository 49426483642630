import "./buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { FaCheck, FaPencilAlt, FaTimes } from "react-icons/fa"

//Components
import CardService from "../../../services/card_services"

const targets = ["player", "business"]

class AdminBuds extends Component {
    constructor(props) {
        super(props)
        this.state = {
            buds: [],
            deactivated: false,
            displaySales: false,
            selectKey: null,
            order: 'asc',
            display: "card",
            collections: [],
            dictCollections: {},
            selected: null,
            target: null,
            lvl: "all",
        }
    }

    componentDidMount = async () => {
        await this.loadCollections()
        await this.loadBuds()
    }

    loadCollections = async () => {
        const collections = await CardService.getCollections()
        let dictCollections = {}
        for (let collection of collections) {
            dictCollections[collection.collection_uuid] = collection
        }
        this.setState({ collections, dictCollections })
    }

    selectCollection = (e) => {
        this.setState({ selected: e.target.value !== "all" ? e.target.value : null })
        this.loadBuds(e.target.value !== "all" ? e.target.value : null)
    }

    loadBuds = async (collection) => {
        const data = collection ? await CardService.getCollectionBuds(collection, this.state.deactivated, this.state.target) : await CardService.getBuds(this.state.deactivated, this.state.target)
        let buds = []
        for (let bud of data) {
            if (bud.sale_info) {
                let coming = 0, current = 0, passed = 0
                for (let sale of bud.sale_info) {
                    if ((!sale.start_sale || (new Date(sale.start_sale) < new Date())) && (!sale.end_sale || (new Date(sale.end_sale) > new Date())))
                        current++
                    else if (sale.start_sale && (new Date(sale.start_sale) > new Date()))
                        coming++
                    else
                        passed++
                }
                bud.coming = coming
                bud.current = current
                bud.passed = passed
            } else {
                bud.coming = 0
                bud.current = 0
                bud.passed = 0
            }
            bud.collection = bud.collection_uuid ? this.state.dictCollections[bud.collection_uuid] : null
            buds.push(bud)
        }
        this.setState({ buds })
    }

    switchFilter = async () => {
        this.setState({ deactivated: !this.state.deactivated }, () => this.loadBuds(this.state.selected))
    }

    activate = async (bud_uuid) => {
        try {
            const data = await CardService.activateBud(bud_uuid)
            NotificationManager.success(data.message)
            this.loadBuds(this.state.selected)
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    deactivate = async (bud_uuid) => {
        try {
            const data = await CardService.deactivateBud(bud_uuid)
            NotificationManager.success(data.message)
            this.loadBuds(this.state.selected)
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    reorder = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let buds = this.state.buds
        if (order === 'asc')
            buds.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            buds.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ buds })
    }



    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0">
                        <button className="button d-line" onClick={() => window.location.href = '/admin/buds/collection/create'}>Create new collection</button>
                        <button className="button d-line" onClick={() => window.location.href = '/admin/buds/create'}>Create new bud</button>
                        <h4 className="left mb-0">
                            Browse available buds
                            {this.state.display === "card" ?
                                <span className="switch" style={{ marginLeft: "10px" }} onClick={() => this.setState({ display: "table" })}>Display table</span>
                                :
                                <span className="switch" style={{ marginLeft: "10px" }} onClick={() => this.setState({ display: "card" })}>Display cards</span>
                            }
                            {this.state.deactivated ?
                                <span className="switch" onClick={this.switchFilter}>Display active buds</span>
                                :
                                <span className="switch" onClick={this.switchFilter}>Display deactivated buds</span>
                            }
                        </h4>
                        <div className="ml-10">
                            <small style={{ lineHeight: "40px" }}>Filter by collection</small>
                            <select className="lined_select" onChange={this.selectCollection}>
                                <option selected value={"all"}>All</option>
                                {this.state.collections.map(collection =>
                                    <option key={collection.collection_uuid} value={collection.collection_uuid}>{collection.collection_name}</option>
                                )}
                            </select>
                            {this.state.selected && <button className="button d-line mt-0" onClick={() => window.location.href = `/admin/buds/collection/update/${this.state.selected}`}><FaPencilAlt /></button>}
                        </div>
                        <div className="mt-20 ml-10">
                            <small style={{ lineHeight: "40px" }}>Filter by target</small>
                            <select className="lined_select" onChange={(e) => this.setState({ target: e.target.value }, () => {
                                this.loadBuds(this.state.selected)
                            })}>
                                <option selected value={"all"}>All</option>
                                {targets.map(target =>
                                    <option key={target} value={target}>{target}</option>
                                )}
                            </select>
                        </div>
                        <div className="mt-20 ml-10">
                            <small style={{ lineHeight: "40px" }}>Filter by level</small>
                            <select className="lined_select" onChange={(e) => this.setState({ lvl: e.target.value })}>
                                <option selected value={"all"}>All</option>
                                {["starter", "common", "rare", "epic", "legendary", "mythic", "eternal"].map(lvl =>
                                    <option key={lvl} value={lvl}>{lvl}</option>
                                )}
                            </select>
                        </div>
                        {this.state.display === "table" && <div className="selecter">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="left" style={{ width: "40px" }}>#</th>
                                        <th style={{ width: "130px" }}>Visual</th>
                                        <th className="filter" style={{ width: "140px" }} onClick={() => this.reorder("bud_level")}>⥃ Level</th>
                                        <th className="filter left" style={{ width: "200px" }} onClick={() => this.reorder("bud_name")}>⥃ Name</th>
                                        <th className="filter left" style={{ width: "95px" }} onClick={() => this.reorder("nb_cards")}>⥃ Distributed</th>
                                        <th className="filter left" style={{ width: "95px" }} onClick={() => this.reorder("current")}>⥃ On sale</th>
                                        <th className="filter left" style={{ width: "130px" }} onClick={() => this.reorder("coming")}>⥃ Sale coming</th>
                                        <th className="filter left" style={{ width: "130px" }} onClick={() => this.reorder("passed")}>⥃ Sale closed</th>
                                        <th style={{ width: "100px" }}>-</th>
                                        <th style={{ width: "50px" }}>-</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.buds.map((bud, index) => (this.state.lvl === "all" || (this.state.lvl === bud.bud_level)) &&
                                        <tr key={`bud_${bud.bud_uuid}`}>
                                            <td>
                                                <strong>{index + 1}</strong>
                                            </td>
                                            <td>
                                                <div className="min_card" style={{ backgroundImage: `url('${bud.img_url}')` }}>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`badge ${bud.bud_level} relative`}>{bud.bud_level}</div>
                                            </td>
                                            <td>
                                                <strong className="left"><small>{bud.bud_name}</small></strong>
                                                <br />
                                                <small className="left"><small>COLLECTION:</small> {bud.collection ? bud.collection.collection_name : '-'}</small>
                                                <br />
                                                <small className="left"><small>TARGET:</small> {bud.target}</small>
                                            </td>
                                            <td>
                                                <strong>{bud.nb_cards}</strong>
                                            </td>
                                            <td>
                                                <strong>{bud.current}</strong>
                                            </td>
                                            <td>
                                                <strong>{bud.coming}</strong>
                                            </td>
                                            <td>
                                                <strong>{bud.passed}</strong>
                                            </td>
                                            <td>
                                                <div className="details relative" onClick={() => window.location.href = `/admin/buds/details/${bud.bud_uuid}?deactivated=${this.state.deactivated}`}>Details</div>
                                            </td>
                                            <td>
                                                {this.state.deactivated ?
                                                    <div className="deactivate relative" onClick={() => this.activate(bud.bud_uuid)}> <FaCheck /> </div>
                                                    :
                                                    <div className="deactivate relative" onClick={() => this.deactivate(bud.bud_uuid)}> <FaTimes /> </div>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>}
                        {this.state.display === "card" && <div className="selecter">
                            {this.state.buds.map(bud => (this.state.lvl === "all" || (this.state.lvl === bud.bud_level)) &&
                                <div className="bud" key={bud.bud_uuid}>
                                    <div className="card" style={{ backgroundImage: `url('${bud.img_url}')` }}>
                                        <div className={`badge ${bud.bud_level}`}>{bud.bud_level}</div>
                                    </div>
                                    <div className="gen">
                                        {bud.bud_name}
                                        <br />
                                        <small className="left"><small>TARGET:</small> {bud.target}</small>
                                        <br />
                                        <small><small>DISTRIBUTED:</small> {bud.nb_cards}</small>
                                        <br />
                                        {bud.collection && <small>
                                            <small>COLLECTION:</small> {bud.collection.collection_name}</small>}
                                    </div>
                                    {!this.state.deactivated && <div className="quantity">
                                        {!bud.sale_info ? "Not for sale" : <span>{bud.sale_info.length} {bud.sale_info.length > 1 ? "sales" : "sale"} $$$</span>}
                                    </div>}
                                    {bud.current > 0 && bud.coming > 0 &&
                                        <div className="price">
                                            {bud.current} on sale | {bud.coming} coming
                                        </div>
                                    }
                                    {bud.current > 0 && bud.coming === 0 &&
                                        <div className="price">
                                            {bud.current} on sale
                                        </div>
                                    }
                                    {bud.current === 0 && bud.coming > 0 &&
                                        <div className="price">
                                            {bud.current} coming
                                        </div>
                                    }
                                    {bud.current === 0 && bud.coming === 0 &&
                                        <div className="price"></div>
                                    }
                                    {this.state.deactivated ?
                                        <div className="deactivate" onClick={() => this.activate(bud.bud_uuid)}> <FaCheck /> </div>
                                        :
                                        <div className="deactivate" onClick={() => this.deactivate(bud.bud_uuid)}> <FaTimes /> </div>
                                    }
                                    <div className="details" onClick={() => window.location.href = `/admin/buds/details/${bud.bud_uuid}?deactivated=${this.state.deactivated}`}>Details</div>
                                    {!this.state.deactivated && <div className="button" onClick={() => window.location.href = `/admin/buds/update/${bud.bud_uuid}`}>
                                        <FaPencilAlt />
                                    </div>}
                                </div>)}
                        </div>}
                    </div>
                </div>
                <NotificationContainer />

            </>
        )
    }
}

export default withTranslation()(AdminBuds)