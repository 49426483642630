import "./accounts.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'

//Components
import Footer from '../../../components/footer'
import AdminService from "../../../services/admin_services"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminUpdateAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            fileData: null,
            fileSize: 0,
            fileExtension: null,
            levels: [],
            admin_uuid: null,
            firstname: null,
            lastname: null,
            email_address: null,
            password: null,
            account: {}
        }
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const accounts = await AdminService.getAdmins()
        for (let acc of accounts) {
            if (acc.admin_uuid === id)
                this.setState({ account: acc, admin_uuid: id })
        }
    }

    update = async () => {
        try {
            this.setState({ processing: true })
            const data = await AdminService.updateAdmin(this.state.email_address, this.state.firstname, this.state.lastname)
            NotificationManager.success(data.message)
            this.setState({ processing: false, success: true, email_address: null, firstname: null, lastname: null })
        } catch (e) {
            this.setState({ processing: false })
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    updatePswd = async () => {
        if (!this.state.password || (this.state.password.length < 8))
            NotificationManager.error('Incorrect format')
        else {
            try {
                this.setState({ processing: true })
                const data = await AdminService.updateAdminPswd(this.state.password)
                NotificationManager.success(data.message)
                this.setState({ processing: false, success: true })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/accounts'}>Accounts</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">Set all required information to update account</h4>
                        <input type="text" placeholder="Firstname" onChange={(e) => this.setState({ firstname: e.target.value })} defaultValue={this.state.account.firstname} />
                        <input type="text" placeholder="Lastname" onChange={(e) => this.setState({ lastname: e.target.value })} defaultValue={this.state.account.lastname} />
                        <input type="email" placeholder="Email" onChange={(e) => this.setState({ email_address: e.target.value })} defaultValue={this.state.account.email_address} />
                        {this.state.processing ?
                            <p>Processing... Please wait</p>
                            :
                            <button className="button" onClick={this.update}>Update account</button>
                        }
                    </div>
                    <div className="selectcard">
                        <h4 className="left mb-0">Set a new password to update</h4>
                        <input type="password" placeholder="New password" onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password} />
                        {this.state.processing ?
                            <p>Processing... Please wait</p>
                            :
                            <button className="button" onClick={this.updatePswd}>Update password</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withRouter(AdminUpdateAccount)