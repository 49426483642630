import "./buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Buffer } from 'buffer'
import { useParams } from 'react-router-dom'

//Components
import CardService from "../../../services/card_services"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminUpdateBud extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            fileData: null,
            fileSize: 0,
            fileExtension: null,
            thumbnailData: null,
            thumbnailSize: 0,
            thumbnailExtension: null,
            levels: [],
            bud_uuid: null,
            level: null,
            bud_name: null,
            description_en: null,
            description_fr: null,
            img_url: null,
            thumbnail_url: null,
            collection: null,
            collection_uuid: null,
            collections: [],
        }
    }

    onFileHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['png'].includes(extension)) {
            NotificationManager.error('Invalid file')
        } else if (event.target.files[0].size > 5000000) {
            NotificationManager.error('File exceeding 5Mo')
        } else {
            await this.setState({ fileSize: event.target.files[0].size, fileExtension: extension })
            reader.onloadend = () => this.processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        this.setState({ fileData: `data:image/${this.state.fileExtension};base64,${base64data}` })
    }

    onThumbnailHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['png'].includes(extension)) {
            NotificationManager.error('Invalid thumbnail')
        } else if (event.target.files[0].size > 5000000) {
            NotificationManager.error('Thumbnail exceeding 5Mo')
        } else {
            await this.setState({ thumbnailSize: event.target.files[0].size, thumbnailExtension: extension })
            reader.onloadend = () => this.processThumbnail(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processThumbnail = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        this.setState({ thumbnailData: `data:image/${this.state.thumbnailExtension};base64,${base64data}` })
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const collections = await CardService.getCollections()
        const buds = await CardService.getBuds()
        for (let bud of buds) {
            if (bud.bud_uuid === id) {
                this.setState({ collections, level: bud.bud_level, bud_name: bud.bud_name, description_fr: bud.description.fr, description_en: bud.description.en || bud.description, img_url: bud.img_url, 
                    thumbnail_url: bud.thumbnail_url, bud_uuid: id, collection_uuid: bud.collection_uuid })
                for (let collection of collections) {
                    if (collection.collection_uuid === bud.collection_uuid)
                        this.setState({ collection })
                }
            }
        }
        const levels = await CardService.getLevels()
        this.setState({ levels })
    }

    update = async () => {
        if (!this.state.level || !this.state.bud_name || !this.state.description_fr || !this.state.description_en)
            NotificationManager.error('Missing information')
        else {
            try {
                this.setState({ processing: true })
                const data = await CardService.updateBud(this.state.bud_uuid, this.state.level, this.state.fileData, this.state.thumbnailData, this.state.bud_name, 
                    {en: this.state.description_en, fr: this.state.description_fr}, this.state.collection_uuid)
                NotificationManager.success(data.message)
                this.setState({ processing: false, success: true })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    setCollection = async (e) => {
        this.setState({ collection_uuid: e.target.value })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">Set all required information to update Bud</h4>
                        <p className="mb-0">HQ bud visual</p>
                        {this.state.fileData && <img src={this.state.fileData} alt="Bud visual" className="bud_visual" />}
                        {!this.state.fileData && this.state.img_url && <img src={this.state.img_url} alt="Bud visual" className="bud_visual" />}
                        <input type="file" placeholder="Select bud visual" className="mb-0" onChange={(e) => this.onFileHandler(e)} />
                        <p className="mt-0"><small>*image .png only</small></p>
                        <p className="mb-0">Thumbnail visual</p>
                        {this.state.thumbnailData && <img src={this.state.thumbnailData} alt="Bud visual" className="bud_visual" />}
                        {!this.state.thumbnailData && this.state.thumbnail_url && <img src={this.state.thumbnail_url} alt="Bud thumbnail" className="bud_visual" />}
                        <input type="file" placeholder="Select bud visual" className="mb-0" onChange={(e) => this.onThumbnailHandler(e)} />
                        <p className="mt-0"><small>*image .png only</small></p>
                        <select onChange={(e) => this.setState({ level: e.target.value })}>
                            <option selected>{this.state.level}</option>
                            {this.state.levels.map(lvl => lvl !== this.state.level &&
                                <option key={lvl} value={lvl}>{lvl}</option>
                            )}
                        </select>
                        <select onChange={this.setCollection}>
                            {this.state.collection ?
                                <option selected value={this.state.collection.collection_uuid}>{this.state.collection.collection_name}</option>
                                :
                                <option selected disabled>Select a collection</option>
                            }
                            {this.state.collections.map(collection =>
                                <option key={collection.collection_uuid} value={collection.collection_uuid}>{collection.collection_name}</option>
                            )}
                        </select>
                        <input type="text" placeholder="Set Bud name" onChange={(e) => this.setState({ bud_name: e.target.value })} value={this.state.bud_name} />
                        <textarea placeholder="Description EN" onChange={(e) => this.setState({ description_en: e.target.value })} value={this.state.description_en}></textarea>
                        <textarea placeholder="Description FR" onChange={(e) => this.setState({ description_fr: e.target.value })} value={this.state.description_fr}></textarea>
                        {this.state.processing ?
                            <p>Processing... Please wait</p>
                            :
                            !this.state.success ?
                                <button className="button" onClick={this.update}>Update bud</button>
                                :
                                <button className="button" onClick={() => window.location.href = "/admin/buds"}>🡐 Back</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withRouter(AdminUpdateBud)