import "./buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'

//Components
import CardService from "../../../services/card_services"
import BusinessService from "../../../services/business_services"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminBudDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            levels: [],
            bud_uuid: null,
            level: null,
            bud_name: null,
            description: '',
            img_url: null,
            sale_info: null,
            collection: null,
            business_uuid: null,
            nb_card: 100,
            businesses: [],
            target: null,
            nb_cards: 0,
        }
    }

    componentDidMount = async () => {
        const { id } = this.props.match.params
        const queryParams = new URLSearchParams(window.location.search)
        const deactivated = queryParams.get("deactivated") || false
        const buds = await CardService.getBuds(deactivated)
        for (let bud of buds) {
            if (bud.bud_uuid === id) {
                console.log(bud.sale_info)
                this.setState({ level: bud.bud_level, bud_name: bud.bud_name, description: bud.description, img_url: bud.img_url, bud_uuid: id, sale_info: bud.sale_info, target: bud.target, nb_cards: bud.nb_cards })
                if (bud.collection_uuid) {
                    const collections = await CardService.getCollections()
                    for (let collection of collections) {
                        if (collection.collection_uuid === bud.collection_uuid)
                            this.setState({ collection })
                    }
                }
            }
        }
        const levels = await CardService.getLevels()
        this.setState({ levels })
        const businesses = await BusinessService.businesses(false, true)
        this.setState({ businesses })
    }

    assignBud = async () => {
        if (!this.state.business_uuid || this.state.nb_card < 1)
            NotificationManager.error(`Please select a business and set min. 1 card`)
        else if (window.confirm("Do you confirm business affiliation?")) {
            this.setState({ processing: true })
            await BusinessService.businessBud(this.state.business_uuid, this.state.bud_uuid, this.state.nb_card)
            NotificationManager.success(`Assignation successfull for ${this.state.nb_card} cards`)
            this.setState({ processing: false })
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0" style={{ paddingBottom: "20px" }} >
                        <h4 className="left mb-0">Bud details</h4>
                        {this.state.img_url && <img src={this.state.img_url} alt="Bud visual" className="bud_visual" />}
                        <h2>
                            {this.state.bud_name} <div className={`lvl ${this.state.level}`}>{this.state.level}</div>
                            <br />
                            <small><small>collection {'>'}</small> {this.state.collection ? this.state.collection.collection_name : '-'}</small>
                            <br />
                            <small><small>distributed {'>'}</small> {this.state.nb_cards}</small>
                            <br/>
                            <small><small>target {'>'}</small> {this.state.target}</small>
                        </h2>
                        {this.state.description.en && <p>EN: {this.state.description.en}</p>}
                        {this.state.description.fr && <p>FR: {this.state.description.fr}</p>}
                        {!this.state.description.en && <p>OLD FORMAT: {this.state.description}</p>}
                        <div className="button" onClick={() => window.location.href = `/admin/buds/update/${this.state.bud_uuid}`}>
                            Update
                        </div>
                        <hr />
                        {this.state.target === "business" && <React.Fragment>
                            <div style={{ maxWidth: "200px" }}>
                                <h2>Assign bud</h2>
                                <small>Business</small>
                                <select onChange={(e) => this.setState({ business_uuid: e.target.value })} style={{ marginTop: 0 }}>
                                    <option selected disabled>Select a business</option>
                                    {this.state.businesses.map(business =>
                                        <option key={business.business_uuid} value={business.business_uuid}>{business.business_name}</option>
                                    )}
                                </select>
                                <small>Nb cards</small>
                                <input type="number" placeholder="Nb cards" value={this.state.nb_card} style={{ margin: "2px 0" }} onChange={(e) => this.setState({ nb_card: e.target.value })} />
                                {!this.state.processing ?
                                    <button className="button d-line" onClick={this.assignBud}>Assign Bud</button>
                                    :
                                    <small>Processing...</small>
                                }
                            </div>
                            <hr />
                        </React.Fragment>}
                        <h2>Sales</h2>
                        {this.state.sale_info && <table className="pb-20">
                            <thead>
                                <tr>
                                    <th className="left" style={{ width: "40px" }}>#</th>
                                    <th className="left" style={{ width: "100px" }}>Price</th>
                                    <th className="left" style={{ width: "120px" }}>Status</th>
                                    <th className="left" style={{ width: "180px" }}>Start date</th>
                                    <th className="left" style={{ width: "180px" }}>End date</th>
                                    <th className="left" style={{ width: "140px" }}>Max sales</th>
                                    <th className="left" style={{ width: "100px" }}>Nb sold</th>
                                    <th className="left" style={{ width: "120px" }}>Target</th>
                                    <th className="left" style={{ width: "40px" }}>XP</th>
                                    <th className="left" style={{ width: "120px" }}>Sale update</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sale_info.map((sale, index) =>
                                    <tr key={sale.bud_sale_uuid}>
                                        <td>
                                            <small>{index + 1}</small>
                                        </td>
                                        <td>
                                            <strong style={{ textAlign: "left" }}>{sale.price} ★</strong>
                                        </td>
                                        <td>
                                            {sale.sale_status ? <small className="success">Active</small> : <small className="error">Not active</small>}
                                        </td>
                                        <td>
                                            <small>
                                                {sale.start_sale ? `${new Date(sale.start_sale).toLocaleDateString()} ${new Date(sale.start_sale).toLocaleTimeString()}` : 'No start date'}
                                            </small>
                                        </td>
                                        <td>
                                            <small>
                                                {new Date(sale.end_sale).toLocaleDateString()} {new Date(sale.end_sale).toLocaleTimeString()}
                                            </small>
                                        </td>
                                        <td>
                                            <strong style={{ textAlign: "left" }}>{sale.max_sales ? sale.max_sales : "Unlimited"}</strong>
                                        </td>
                                        <td>
                                            <strong style={{ textAlign: "left" }}>{sale.nb_sold}</strong>
                                        </td>
                                        <td>
                                            <small style={{ textAlign: "left" }}>{sale.target}</small>
                                        </td>
                                        <td>
                                            <small style={{ textAlign: "left" }}><strong>{sale.xp}</strong></small>
                                        </td>
                                        <td>
                                            <p onClick={() => window.location.href = `/admin/buds/sale/update/${this.state.bud_uuid}/${sale.bud_sale_uuid}`}>
                                                <strong className="pointer success">Update</strong>
                                            </p>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>}
                        <div className="button" onClick={() => window.location.href = `/admin/buds/sale/new/${this.state.bud_uuid}`}>
                            New sale
                        </div>
                    </div>
                </div>
                <NotificationContainer />

            </>
        )
    }
}

export default withRouter(AdminBudDetails)