import "./accounts.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { FaCheck, FaTimes } from "react-icons/fa"
import TokenService from "../../../services/token_services"
import bud1 from "../../../media/images/buds/01.png"
import bud2 from "../../../media/images/buds/02.png"
import bud3 from "../../../media/images/buds/03.png"
import bud4 from "../../../media/images/buds/04.png"
import bud5 from "../../../media/images/buds/05.png"
import bud6 from "../../../media/images/buds/06.png"
import bud7 from "../../../media/images/buds/07.png"
import bud8 from "../../../media/images/buds/08.png"
import bud9 from "../../../media/images/buds/09.png"
import bud10 from "../../../media/images/buds/10.png"
import bud11 from "../../../media/images/buds/11.png"
import bud12 from "../../../media/images/buds/12.png"
import bud13 from "../../../media/images/buds/13.png"
import bud14 from "../../../media/images/buds/14.png"
import bud15 from "../../../media/images/buds/15.png"
import bud16 from "../../../media/images/buds/16.png"
import bud17 from "../../../media/images/buds/17.png"
import bud18 from "../../../media/images/buds/18.png"
import bud19 from "../../../media/images/buds/19.png"
import bud20 from "../../../media/images/buds/20.png"
import bud21 from "../../../media/images/buds/21.png"
import bud22 from "../../../media/images/buds/22.png"
import bud23 from "../../../media/images/buds/23.png"
import bud24 from "../../../media/images/buds/24.png"

//Components
import AdminService from "../../../services/admin_services"

const buds = [bud1, bud2, bud3, bud4, bud5, bud6, bud7, bud8, bud9, bud10, bud11, bud12, bud13, bud14, bud15, bud16, bud17, bud18, bud19, bud20, bud21, bud22, bud23, bud24]

class AdminAccounts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accounts: [],
            deactivated: false,
            account: {},
        }
    }

    componentDidMount = async() => {
        this.loadAccounts()
        const user = await TokenService.getUser()
        this.setState({account: user? user.account : {}})
    }

    loadAccounts = async () => {
        const accounts = await AdminService.getAdmins(this.state.deactivated)
        this.setState({ accounts })
    }

    switchFilter = async () => {
        this.setState({ deactivated: !this.state.deactivated }, () => this.loadAccounts())
    }

    activate = async (admin_uuid) => {
        try {
            const data = await AdminService.activateAdmin(admin_uuid)
            NotificationManager.success(data.message)
            this.loadAccounts()
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    deactivate = async (admin_uuid) => {
        try {
            const data = await AdminService.deactivateAdmin(admin_uuid)
            NotificationManager.success(data.message)
            this.loadAccounts()
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/accounts'}>Accounts</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">
                            Browse administration accounts
                            {this.state.deactivated ?
                                <span className="switch" onClick={this.switchFilter}>Display active accounts</span>
                                :
                                <span className="switch" onClick={this.switchFilter}>Display deactivated accounts</span>
                            }
                        </h4>
                        <button className="button" onClick={() => window.location.href = '/admin/account/create'}>Create new account</button>
                        <div className="selecter">
                            {this.state.accounts.map(acc => <div className="bud" key={acc.admin_uuid}>
                                <div className="card" style={{ backgroundImage: `url('${buds[acc.email_address.toLowerCase().charCodeAt(0) - 97]}')` }}>
                                    <div className={`badge starter`}>ADMIN</div>
                                </div>
                                <div className="gen">{acc.firstname} {acc.lastname}</div>
                                <p className="center mt-0"><small>{acc.email_address}</small></p>
                                {this.state.deactivated ?
                                    <div className="deactivate" onClick={() => this.activate(acc.admin_uuid)}> <FaCheck /> </div>
                                    :
                                    <div className="deactivate" onClick={() => this.deactivate(acc.admin_uuid)}> <FaTimes /> </div>
                                }
                                {this.state.account.admin_uuid === acc.admin_uuid && <div className="button update" onClick={() => window.location.href=`/admin/account/update/${acc.admin_uuid}`}>
                                    Update
                                </div>}
                            </div>)}
                        </div>
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withTranslation()(AdminAccounts)