import "./buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { Buffer } from 'buffer'

//Components
import Footer from '../../../components/footer'
import CardService from "../../../services/card_services"

const targets = ["player", "business"]

class AdminCreateBud extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            fileData: null,
            fileSize: 0,
            fileExtension: null,
            thumbnailData: null,
            thumbnailSize: 0,
            thumbnailExtension: null,
            levels: [],
            collections: [],
            level: null,
            bud_name: null,
            description_en: null,
            description_fr: null,
            collection_uuid: null,
            target: null,
            discord: false,
            twitter: false,
        }
    }

    loadCollections = async () => {
        const collections = await CardService.getCollections()
        this.setState({ collections })
    }

    onFileHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['png'].includes(extension)) {
            NotificationManager.error('Invalid file')
        } else if (event.target.files[0].size > 5000000) {
            NotificationManager.error('File exceeding 5Mo')
        } else {
            await this.setState({ fileSize: event.target.files[0].size, fileExtension: extension })
            reader.onloadend = () => this.processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        this.setState({ fileData: `data:image/${this.state.fileExtension};base64,${base64data}` })
    }

    onThumbnailHandler = async (event) => {
        const reader = new FileReader()
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['png'].includes(extension)) {
            NotificationManager.error('Invalid thumbnail')
        } else if (event.target.files[0].size > 5000000) {
            NotificationManager.error('Thumbnail exceeding 5Mo')
        } else {
            await this.setState({ thumbnailSize: event.target.files[0].size, thumbnailExtension: extension })
            reader.onloadend = () => this.processThumbnail(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processThumbnail = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        this.setState({ thumbnailData: `data:image/${this.state.thumbnailExtension};base64,${base64data}` })
    }

    componentDidMount = async () => {
        this.loadCollections()
        const levels = await CardService.getLevels()
        this.setState({ levels })
    }

    setCollection = async (e) => {
        this.setState({ collection_uuid: e.target.value })
        const nb = await CardService.getCollectionNumber(e.target.value)
        for (let collection of this.state.collections) {
            if (collection.collection_uuid === e.target.value)
                this.setState({ bud_name: ` ${collection.collection_name.at(-1) === 's'? collection.collection_name.slice(0, -1) : collection.collection_name} #${nb}` })
        }
    }

    create = async () => {
        if (!this.state.fileData || !this.state.thumbnailData || !this.state.level || !this.state.bud_name || !this.state.description_fr || !this.state.description_en || !this.state.collection_uuid || !this.state.target)
            NotificationManager.error('Missing information')
        else {
            try {
                this.setState({ processing: true })
                const data = await CardService.createBud(this.state.level, this.state.fileData, this.state.thumbnailData, this.state.bud_name, 
                    {en: this.state.description_en, fr: this.state.description_fr}, this.state.collection_uuid, this.state.target, this.state.discord, this.state.twitter)
                NotificationManager.success(data.message)
                this.setState({ processing: false, success: true })
            } catch (e) {
                this.setState({ processing: false })
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">Set all required information to create a new Bud {this.state.fileExtension}</h4>
                        <p className="mb-0">HQ bud visual</p>
                        {this.state.fileData && <img src={this.state.fileData} alt="Bud visual" className="bud_visual" />}
                        <input type="file" placeholder="Select bud visual" className="mb-0" onChange={(e) => this.onFileHandler(e)} />
                        <p className="mt-0"><small>*image .png only</small></p>
                        <p className="mb-0">Thumbnail</p>
                        {this.state.thumbnailData && <img src={this.state.thumbnailData} alt="Bud visual" className="bud_visual" />}
                        <input type="file" placeholder="Select bud thumbnail" className="mb-0" onChange={(e) => this.onThumbnailHandler(e)} />
                        <p className="mt-0"><small>*image .png only</small></p>
                        <select onChange={(e) => this.setState({ level: e.target.value })}>
                            <option selected disabled>Select a starting level</option>
                            {this.state.levels.map(lvl =>
                                <option key={lvl} value={lvl}>{lvl}</option>
                            )}
                        </select>
                        <select onChange={this.setCollection}>
                            <option selected disabled>Select a collection</option>
                            {this.state.collections.map(collection =>
                                <option key={collection.collection_uuid} value={collection.collection_uuid}>{collection.collection_name}</option>
                            )}
                        </select>
                        <input type="text" placeholder="Set Bud name" value={this.state.bud_name} onChange={(e) => this.setState({ bud_name: e.target.value })} />
                        <textarea placeholder="Description EN" onChange={(e) => this.setState({ description_en: e.target.value })}></textarea>
                        <textarea placeholder="Description FR" onChange={(e) => this.setState({ description_fr: e.target.value })}></textarea>
                        <select onChange={(e) => this.setState({ target: e.target.value })}>
                            <option selected disabled>Select a target</option>
                            {targets.map(target =>
                                <option key={target} value={target}>{target}</option>
                            )}
                        </select>
                        <p>Discord push?</p>
                        <input className="inline" type="radio" value={true} name="discord" onClick={() => this.setState({discord: true})} checked={this.state.discord}/> Yes | <input className="inline" type="radio" value={false} name="discord" onClick={() => this.setState({discord: false})} checked={!this.state.discord}/> No
                        <p>Twitter push?</p>
                        <input className="inline" type="radio" value={true} name="twitter" onClick={() => this.setState({twitter: true})} checked={this.state.twitter}/> Yes | <input className="inline" type="radio" value={false} name="twitter" onClick={() => this.setState({twitter: false})} checked={!this.state.twitter}/> No
                        {this.state.processing ?
                            <p>Processing... Please wait</p>
                            :
                            !this.state.success ?
                                <button className="button" onClick={this.create}>Create new bud</button>
                                :
                                <button className="button" onClick={() => window.location.href = "/admin/buds"}>🡐 Back</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withTranslation()(AdminCreateBud)