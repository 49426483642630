import "./stats.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Chart from "react-apexcharts"

//Components
import AdminService from "../../../services/admin_services"

const d = new Date();


class AdminStatGems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_action: 6,
            actions_info: {},
            period_average: {},
            stars_daily_average: {},
            stars_daily_stats: {},
            end_date: new Date().toISOString().substring(0, 10),
            start_date: new Date(d.setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10),
            options_stars: null,
            series_star: null,
            current: {}
        }
    }

    componentDidMount = async () => {
        await this.loadStats()
    }

    loadStats = async () => {
        const stars = await AdminService.getStarsUsage(this.state.start_date, this.state.end_date, this.state.selected_action)
        const actions_by_player = [], nb_actions = [], nb_players = [], nb_stars = [], stars_by_action = [], stars_by_player = []
        Object.keys(stars.daily_stats).map(key => actions_by_player.push(stars.daily_stats[key].actions_by_player))
        Object.keys(stars.daily_stats).map(key => nb_actions.push(stars.daily_stats[key].nb_actions))
        Object.keys(stars.daily_stats).map(key => nb_players.push(stars.daily_stats[key].nb_players))
        Object.keys(stars.daily_stats).map(key => nb_stars.push(stars.daily_stats[key].nb_assets))
        Object.keys(stars.daily_stats).map(key => stars_by_action.push(stars.daily_stats[key].assets_by_action))
        Object.keys(stars.daily_stats).map(key => stars_by_player.push(stars.daily_stats[key].assets_by_player))
        this.setState({
            actions_info: stars.actions_info,
            period_average: stars.period_average,
            stars_daily_average: stars.daily_average,
            stars_daily_stats: stars.daily_stats,
            options_stars: { chart: { id: "stars-bar" }, xaxis: { categories: Object.keys(stars.daily_stats) } },
            actions_by_player: [{ name: "stars", data: actions_by_player }],
            nb_actions: [{ name: "stars", data: nb_actions }],
            nb_players: [{ name: "stars", data: nb_players }],
            nb_stars: [{ name: "stars", data: nb_stars }],
            stars_by_action: [{ name: "stars", data: stars_by_action }],
            stars_by_player: [{ name: "stars", data: stars_by_player }],
        })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats'}>Statistics</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats/stars'}>Stars</span></h2>
                    <div className="selectcard mt-0">
                        <h2 className="left mb-0">
                        💎 Gems statistics
                        </h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5>Start date: <input type="date" defaultValue={this.state.start_date} onChange={(e) => this.setState({ start_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                    <td>
                                        <h5>End date: <input type="date" defaultValue={this.state.end_date} onChange={(e) => this.setState({ end_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>
                            Actions info:
                            <select onChange={(e) => this.setState({ selected_action: e.target.value }, () => {
                                this.loadStats()
                            })}>
                                <option>{this.state.actions_info[this.state.selected_action]}</option>
                                {Object.keys(this.state.actions_info).map(key => key !== this.state.selected_action &&
                                    <option value={key}>{this.state.actions_info[key]}</option>
                                )}
                            </select>
                        </h5>
                        <table cellPadding={5} border={1} width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th colSpan={3}>
                                        Daily average
                                    </th>
                                    <th colSpan={3}>
                                        Period average
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>
                                        Nb Actions
                                    </th>
                                    <th>
                                        Nb Players
                                    </th>
                                    <th>
                                        Nb Gems
                                    </th>
                                    <th>
                                        Actions by player
                                    </th>
                                    <th>
                                        Stars by action
                                    </th>
                                    <th>
                                        Stars by player
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <h5>{this.state.stars_daily_average.nb_actions}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.stars_daily_average.nb_players}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.stars_daily_average.nb_assets}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.period_average.actions_by_player}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.period_average.assets_by_action}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.period_average.assets_by_player}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>Actions by player</h5>
                        {this.state.actions_by_player && <Chart
                            options={this.state.options_stars}
                            series={this.state.actions_by_player}
                            type="bar"
                        />}
                        <h5>Nb actions</h5>
                        {this.state.nb_actions && <Chart
                            options={this.state.options_stars}
                            series={this.state.nb_actions}
                            type="bar"
                        />}
                        <h5>Nb players</h5>
                        {this.state.nb_players && <Chart
                            options={this.state.options_stars}
                            series={this.state.nb_players}
                            type="bar"
                        />}
                        <h5>Nb stars</h5>
                        {this.state.nb_stars && <Chart
                            options={this.state.options_stars}
                            series={this.state.nb_stars}
                            type="bar"
                        />}
                        <h5>Stars by action</h5>
                        {this.state.stars_by_action && <Chart
                            options={this.state.options_stars}
                            series={this.state.stars_by_action}
                            type="bar"
                        />}
                        <h5>Stars by player</h5>
                        {this.state.stars_by_player && <Chart
                            options={this.state.options_stars}
                            series={this.state.stars_by_player}
                            type="bar"
                        />}
                    </div>
                </div>
                
            </>
        )
    }
}

export default withTranslation()(AdminStatGems)