import "./stats.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Chart from "react-apexcharts"

//Components
import AdminService from "../../../services/admin_services"

const d = new Date();


class AdminStatPlayers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nb_active_players: 0,
            total_nb_players: 0,
            end_date: new Date().toISOString().substring(0, 10),
            start_date: new Date(d.setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10),
            series_star: null,
            player_retention: {},
        }
    }

    componentDidMount = async () => {
        await this.loadStats()
    }

    loadStats = async () => {
        const players = await AdminService.getPlayersStats(this.state.start_date, this.state.end_date)
        console.log(players)
        let nb_months = [], nb_days = [], nb_signin_days = [], nb_unique_signin_days = []
        Object.keys(players.created_players_by_month).map(key => nb_months.push(players.created_players_by_month[key]))
        Object.keys(players.created_players_by_day).map(key => nb_days.push(players.created_players_by_day[key]))
        Object.keys(players.signin_by_day).map(key => nb_signin_days.push(players.signin_by_day[key]))
        Object.keys(players.unique_signin_by_day).map(key => nb_unique_signin_days.push(players.unique_signin_by_day[key]))
        this.setState({
            options_days: { chart: { id: "players-day" }, xaxis: { categories: Object.keys(players.created_players_by_day) } },
            options_signin_days: { chart: { id: "signin-day" }, xaxis: { categories: Object.keys(players.signin_by_day) } },
            options_unique_signin_days: { chart: { id: "unique-signin-day" }, xaxis: { categories: Object.keys(players.unique_signin_by_day) } },
            options_months: { chart: { id: "players-month" }, xaxis: { categories: Object.keys(players.created_players_by_month) } },
            nb_months: [{ name: "players", data: nb_months }],
            nb_days: [{ name: "players", data: nb_days }],
            nb_signin_days: [{ name: "players", data: nb_signin_days }],
            nb_unique_signin_days: [{ name: "players", data: nb_unique_signin_days }],
            nb_active_players: players.nb_active_players,
            total_nb_players: players.total_nb_players,
            player_retention: players.player_retention,
        })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats'}>Statistics</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats/players'}>Players</span></h2>
                    <div className="selectcard mt-0">
                        <h2 className="left mb-0">
                            🤾 Players statistics
                        </h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5>Start date: <input type="date" defaultValue={this.state.start_date} onChange={(e) => this.setState({ start_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                    <td>
                                        <h5>End date: <input type="date" defaultValue={this.state.end_date} onChange={(e) => this.setState({ end_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellPadding={5} border={1} width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <th>
                                        Active players
                                    </th>
                                    <th>
                                        Total players
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <h5>{this.state.nb_active_players}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.total_nb_players}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h4>Players retention</h4>
                        <table cellPadding={5} border={1} width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <th>
                                        1 day
                                    </th>
                                    <th>
                                        7 days
                                    </th>
                                    <th>
                                        30 days
                                    </th>
                                    <th>
                                        90 days
                                    </th>
                                    <th>
                                        Total players
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <h5>{this.state.player_retention["1day"]}%</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.player_retention["7days"]}%</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.player_retention["30days"]}%</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.player_retention["90days"]}%</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.player_retention.total}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>New players per month</h5>
                        {this.state.nb_months && <Chart
                            options={this.state.options_months}
                            series={this.state.nb_months}
                            type="bar"
                        />}
                        <h5>New players per day</h5>
                        {this.state.nb_days && <Chart
                            options={this.state.options_days}
                            series={this.state.nb_days}
                            type="bar"
                        />}
                        <h5>Signin per day</h5>
                        {this.state.nb_signin_days && <Chart
                            options={this.state.options_signin_days}
                            series={this.state.nb_signin_days}
                            type="bar"
                        />}
                        <h5>Unique Signin per day</h5>
                        {this.state.nb_unique_signin_days && <Chart
                            options={this.state.options_unique_signin_days}
                            series={this.state.nb_unique_signin_days}
                            type="bar"
                        />}
                    </div>
                </div>

            </>
        )
    }
}

export default withTranslation()(AdminStatPlayers)