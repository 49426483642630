import api from './api';
import TokenService from './token_services';

class BusinessService {
  async register(user_email, password, business_name, user_device, bud_uuid) {
    return await api
      .post('/business/register', { user_email, password, business_name, user_device, bud_uuid })
      .then(async (response) => {
        if (response.data.jwt_token) {
          await TokenService.setUser(response.data);
        }
        return response.data.status;
      });
  }

  async login(email, password, device) {
    try {
      return await api
        .post('/business/user/login', { email, password, device })
        .then(async (response) => {
          if (response.data.jwt_token) {
            await TokenService.setUser(response.data);
          }
          return response.data.status;
        });
    } catch (e) { return false }
  }

  async updateInfo(website, address, city, country, description, logo, business_name) {
    return await api
      .post(`/business/update`, { website, address, city, country, description, logo, business_name })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async business() {
    return await api
      .get('/business/account')
      .then(async (response) => {
        return response.data;
      });
  }

  async budsBusiness() {
    return await api
      .get('/business/bud')
      .then(async (response) => {
        return response.data.buds;
      });
  }

  async purchases() {
    return await api
      .get('/business/purchase')
      .then(async (response) => {
        return response.data.purchase_history;
      });
  }

  async setDefaultBud(bud_uuid) {
    return await api
      .post(`/business/bud/default`, { bud_uuid })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async budDistributions(bud_uuid, expired = false) {
    return await api
      .get(`/business/bud/distribute/${bud_uuid}?expired=${expired}`)
      .then(async (response) => {
        return response.data.bud_distributions;
      });
  }

  async setBudDistribution(bud_uuid, nb_card, expiration_date) {
    return await api
      .post(`/business/bud/distribute`, { bud_uuid, nb_card, expiration_date })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async stopBudDistribution(bud_uuid, distribution_uuid) {
    return await api
      .post(`/business/bud/distribute/stop`, { bud_uuid, distribution_uuid })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async reactivateBudDistribution(expiration_date, distribution_uuid, nb_card) {
    return await api
      .post(`/business/bud/distribute/reactivate`, { expiration_date, distribution_uuid, nb_card })
      .then(async (response) => {
        return response.data.status;
      });
  }

  // Only ADMIN
  async businesses(deactivated = false, registered = false) {
    return await api
      .get(`/business?deactivated=${deactivated}&registered=${registered}`)
      .then(async (response) => {
        return response.data.businesses;
      });
  }
  
  async businessDetails(business_uuid) {
    return await api
      .get(`/business/${business_uuid}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async deactivateBusiness(business_uuid) {
    return await api
      .post(`/business/deactivate`, { business_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async activateBusiness(business_uuid) {
    return await api
      .post(`/business/reactivate`, { business_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async newBusinessActivity(name_en, name_fr, desc_en, desc_fr) {
    return await api
      .post(`/business/activity`, !desc_en && !desc_fr?{
        name: {
          en: name_en,
          fr: name_fr
        }
      }:{
        name: {
          en: name_en,
          fr: name_fr
        },
        description: {
          en: desc_en,
          fr: desc_fr
        }
      })
      .then(async (response) => {
        return response.data;
      });
  }

  async businessActivities(deactivated = false) {
    return await api
      .get(`/business/activity?deactivated=${deactivated}`)
      .then(async (response) => {
        return response.data.activities;
      });
  }

  async deactivateActivity(business_activity_uuid) {
    return await api
      .post(`/business/activity/deactivate`, { business_activity_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async activateActivity(business_activity_uuid) {
    return await api
      .post(`/business/activity/reactivate`, { business_activity_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async businessBud(business_uuid, bud_uuid, nb_card) {
    return await api
      .post(`/business/bud/gift`, { business_uuid, bud_uuid, nb_card })
      .then(async (response) => {
        return response.data;
      });
  }
}
// eslint-disable-next-line
export default new BusinessService();