import "./players.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { FaGem, FaStar, FaTrophy } from "react-icons/fa"

//Components
import player_services from "../../../services/player_services"

class AdminPlayers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            players: [],
            selectKey: null,
            order: 'asc',
            loading: false,
            username: null,
            selectedPlayers: [],
            stars: 0,
            sending: false,
            email_set: false,
            notif_set: false,
            reason: null,
            subject: null,
            message: null,
            deactivated: false,
        }
    }

    searchPlayers = async (e) => {
        if (e.key === 'Enter') {
            if (!this.state.username || this.state.username.length < 3)
                NotificationManager.error('Please set a username with at least 3 chars')
            else {
                this.setState({ loading: true })
                try {
                    const players = await player_services.searchPlayers(this.state.username, this.state.email_set, this.state.notif_set, this.state.deactivated)
                    if (players.length === 0) NotificationManager.warning('No players found :/')
                    this.setState({ players, loading: false, selectedPlayers: [] })
                } catch (e) {
                    NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
                    this.setState({ loading: false })
                }
            }
        }
    }

    selectPlayer = async (player_uuid) => {
        if (!this.state.selectedPlayers.includes(player_uuid)) {
            this.setState({
                selectedPlayers: [...this.state.selectedPlayers, player_uuid]
            })
        } else {
            let selectedPlayers = this.state.selectedPlayers
            const index = selectedPlayers.indexOf(player_uuid);
            selectedPlayers.splice(index, 1);
            this.setState({ selectedPlayers })
        }
    }

    selectAll = async () => {
        if (this.state.selectedPlayers.length === this.state.players.length) {
            this.setState({ selectedPlayers: [] })
        } else {
            let selectedPlayers = []
            for (let p of this.state.players) {
                selectedPlayers.push(p.player_uuid)
            }
            this.setState({ selectedPlayers })
        }
    }

    sendStars = async () => {
        if (!this.state.stars || this.state.selectedPlayers.length < 1)
            NotificationManager.error('Please send at least 1★ to min. 1 player')
        else {
            this.setState({ sending: true })
            await player_services.sendPlayerStars(this.state.selectedPlayers, parseInt(this.state.stars), this.state.reason, this.state.subject, this.state.message)
            NotificationManager.success('Sent successfully')
            this.setState({ sending: false })
        }
    }

    reorder = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let players = this.state.players
        if (order === 'asc')
            players.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            players.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ players })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/players'}>Players</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">
                            Search players
                        </h4>
                        <input type="text" placeholder="Search and press enter" onKeyUp={this.searchPlayers} onChange={(e) => this.setState({ username: e.target.value })} />
                        <table>
                            <tr>
                                <td>
                                    <input type="checkbox" style={{ width: 'auto', margin: 6, }} value={this.state.email_set} onClick={() => this.setState({ email_set: !this.state.email_set })} />
                                </td>
                                <td>
                                    <label>
                                        <small>Email registered</small>
                                    </label>
                                </td>
                                <td>
                                    <input type="checkbox" style={{ width: 'auto', margin: 6, marginLeft: 20 }} value={this.state.notif_set} onClick={() => this.setState({ notif_set: !this.state.notif_set })} />
                                </td>
                                <td>
                                    <label>
                                        <small>Notifications active</small>
                                    </label>
                                </td>
                                <td>
                                    <input type="checkbox" style={{ width: 'auto', margin: 6, marginLeft: 20 }} value={this.state.deactivated} onClick={() => this.setState({ deactivated: !this.state.deactivated })} />
                                </td>
                                <td>
                                    <label>
                                        <small>Deactivated</small>
                                    </label>
                                </td>
                            </tr>
                        </table>
                        <hr />
                        {this.state.loading ?
                            <p>Loading...</p>
                            :
                            this.state.players.length > 0 && <div className="selecter">
                                <h2>{this.state.players.length} players found</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="left"><input type="checkbox" style={{ width: "20px" }} checked={this.state.selectedPlayers.length === this.state.players.length} onChange={this.selectAll} /></th>
                                            <th style={{ width: "130px" }}>Avatar</th>
                                            <th className="filter left" style={{ width: "100px" }} onClick={() => this.reorder("username")}>⥃ Username</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("pk_hash")}>⥃ Pkh</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("email")}>⥃ Email</th>
                                            <th className="filter left" style={{ width: "140px" }}>Registration</th>
                                            <th className="filter left" style={{ width: "140px" }}>Last seen</th>
                                            <th className="filter left" style={{ width: "80px" }}>Notif</th>
                                            <th className="filter left" style={{ width: "80px" }} onClick={() => this.reorder("trophies")}>⥃ <FaTrophy /></th>
                                            <th className="filter left" style={{ width: "80px" }} onClick={() => this.reorder("stars")}>⥃ <FaStar /></th>
                                            <th className="filter left" style={{ width: "80px" }} onClick={() => this.reorder("gems")}>⥃ <FaGem /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.players.map(player =>
                                            <tr key={`player_${player.player_uuid}`}>
                                                <td>
                                                    <input type="checkbox" style={{ width: "20px" }} checked={this.state.selectedPlayers.includes(player.player_uuid)} onChange={() => this.selectPlayer(player.player_uuid)} />
                                                </td>
                                                <td>
                                                    {player.avatar && <div className="min_card" style={{ backgroundImage: `url('${player.avatar}')` }}>
                                                    </div>}
                                                </td>
                                                <td>
                                                    <strong className="left ml-10"><small>{player.username}</small></strong>
                                                </td>
                                                <td>
                                                    <small className="left">
                                                        <small>{player.pk_hash}</small>
                                                    </small>
                                                </td>
                                                <td>
                                                    <small className="left"><small>{player.email}</small></small>
                                                </td>
                                                <td>
                                                    <small className="left">{new Date(player.created_date).toLocaleDateString()} {new Date(player.created_date).toLocaleTimeString()}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{new Date(player.last_seen).toLocaleDateString()} {new Date(player.last_seen).toLocaleTimeString()}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{player.notifications ? "✅" : "❌"}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{player.trophies}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{player.stars}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{player.gems}</small>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <input type="number" placeholder="Set ★ to send to selected players"
                                    style={{ maxWidth: "350px" }}
                                    onChange={(e) => this.setState({ stars: e.target.value })} />
                                <input type="text" placeholder="Reason"
                                    style={{ maxWidth: "350px" }}
                                    onChange={(e) => this.setState({ reason: e.target.value })} />
                                <input type="text" placeholder="Subject"
                                    style={{ maxWidth: "350px" }}
                                    onChange={(e) => this.setState({ subject: e.target.value })} />
                                <input type="text" placeholder="Message"
                                    style={{ maxWidth: "350px" }}
                                    onChange={(e) => this.setState({ message: e.target.value })} />
                                {this.state.sending ?
                                    <span>Sending ★...</span>
                                    :
                                    <button className="button d-line" onClick={this.sendStars}>Send stars</button>}
                            </div>}
                    </div>
                </div>
                <NotificationContainer />
            </>
        )
    }
}

export default withTranslation()(AdminPlayers)