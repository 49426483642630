import "./trackers.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'

//Components
import AdminService from "../../../services/admin_services"

class AdminTrackers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            trackers: [],
            deactivated: false,
            selectKey: null,
            order: 'asc',
            selected: [],
            processing: false,
        }
    }

    componentDidMount = async () => {
        await this.loadTrackers()
    }

    loadTrackers = async () => {
        const trackers = await AdminService.getTrackers()
        this.setState({ trackers })
    }

    reorder = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let trackers = this.state.trackers
        if (order === 'asc')
            trackers.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            trackers.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ trackers })
    }

    createNewTracker = async () => {
        if (window.confirm("Do you confirm tracker creation?")) {
            this.setState({processing: true})
            await AdminService.createTracker(this.state.title)
            NotificationManager.success('Tracker created')
            await this.loadTrackers()
            this.setState({processing: false})
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/trackers'}>Trackers</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">
                            Browse registered trackers
                        </h4>
                        {this.state.processing ?
                            <p>Processing... please wait</p>
                            :
                            <div className="selecter">
                                <div style={{ maxWidth: "200px" }}>
                                    <small>New tracker</small>
                                    <input type="text" placeholder="Title" value={this.state.title} style={{ margin: "2px 0" }} onChange={(e) => this.setState({ title: e.target.value })} />
                                    {!this.state.processing ?
                                        <button className="button d-line" onClick={this.createNewTracker}>Create new tracker</button>
                                        :
                                        <small>Processing...</small>
                                    }
                                </div>
                                <table className="mt-20">
                                    <thead>
                                        <tr>
                                            <th className="left" style={{ width: "40px" }}>#</th>
                                            <th className="filter left" style={{ width: "220px" }} onClick={() => this.reorder("title")}>⥃ Title</th>
                                            <th className="filter left" style={{ width: "220px" }} onClick={() => this.reorder("created_date")}>⥃ Registration date</th>
                                            <th className="filter left" style={{ width: "280px" }} onClick={() => this.reorder("token")}>⥃ Token</th>
                                            <th className="filter left" style={{ width: "120px" }} onClick={() => this.reorder("counter")}>⥃ Counter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.trackers.map((tracker, index) =>
                                            <tr key={`business_${tracker.marketing_tracker_uuid}`} style={{ height: "40px" }}>
                                                <td>
                                                    <strong>{index + 1}</strong>
                                                </td>
                                                <td>
                                                    <strong className="left ml-10"><small>{tracker.title}</small></strong>
                                                </td>
                                                <td>
                                                    <small className="left">{tracker.created_date.substring(0, 19).replace("T", " ")}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{tracker.token}</small>
                                                </td>
                                                <td>
                                                    <strong className="left">{tracker.counter}</strong>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
                <NotificationContainer />

            </>
        )
    }
}

export default withTranslation()(AdminTrackers)