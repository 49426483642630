import "./stats.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Chart from "react-apexcharts"

//Components
import AdminService from "../../../services/admin_services"

const d = new Date();


class AdminStatGames extends Component {
    constructor(props) {
        super(props)
        this.state = {
            games_by_day: {},
            games_by_hour: {},
            games_by_month: {},
            games_by_player: 0,
            games_player_by_day: 0,
            average_games_by_day: 0,
            end_date: new Date().toISOString().substring(0, 10),
            start_date: new Date(d.setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10),
            series_star: null,
        }
    }

    componentDidMount = async () => {
        await this.loadStats()
    }

    loadStats = async () => {
        const games = await AdminService.getGamesStats(this.state.start_date, this.state.end_date)
        let nb_months = [], nb_days = [], nb_hours = []
        Object.keys(games.games_by_month).map(key => nb_months.push(games.games_by_month[key]))
        Object.keys(games.games_by_day).map(key => nb_days.push(games.games_by_day[key]))
        Object.keys(games.games_by_hour).map(key => nb_hours.push(games.games_by_hour[key]))
        this.setState({
            options_days: { chart: { id: "games-day" }, xaxis: { categories: Object.keys(games.games_by_day) } },
            options_months: { chart: { id: "games-month" }, xaxis: { categories: Object.keys(games.games_by_month) } },
            options_hours: { chart: { id: "games-hour" }, xaxis: { categories: Object.keys(games.games_by_hour) } },
            nb_months: [{ name: "games", data: nb_months }],
            nb_days: [{ name: "games", data: nb_days }],
            nb_hours: [{ name: "games", data: nb_hours }],
            games_by_player: games.games_by_player,
            games_player_by_day: games.games_player_by_day,
            average_games_by_day: games.average_games_by_day,
        })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats'}>Statistics</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats/games'}>Games</span></h2>
                    <div className="selectcard mt-0">
                        <h2 className="left mb-0">
                            ⚔️ Games statistics
                        </h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h5>Start date: <input type="date" defaultValue={this.state.start_date} onChange={(e) => this.setState({ start_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                    <td>
                                        <h5>End date: <input type="date" defaultValue={this.state.end_date} onChange={(e) => this.setState({ end_date: e.target.value }, () => {
                                            this.loadStats()
                                        })} /></h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellPadding={5} border={1} width={"100%"} style={{ borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <th>
                                        Av. Games per player
                                    </th>
                                    <th>
                                        Av. Games per player per day
                                    </th>
                                    <th>
                                        Av. Games per day
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <h5>{this.state.games_by_player}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.games_player_by_day}</h5>
                                    </td>
                                    <td>
                                        <h5>{this.state.average_games_by_day}</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>Games per month</h5>
                        {this.state.nb_months && <Chart
                            options={this.state.options_months}
                            series={this.state.nb_months}
                            type="bar"
                        />}
                        <h5>Games per day</h5>
                        {this.state.nb_days && <Chart
                            options={this.state.options_days}
                            series={this.state.nb_days}
                            type="bar"
                        />}
                        <h5>Games per hour</h5>
                        {this.state.nb_hours && <Chart
                            options={this.state.options_hours}
                            series={this.state.nb_hours}
                            type="bar"
                        />}
                    </div>
                </div>

            </>
        )
    }
}

export default withTranslation()(AdminStatGames)