import "./business.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer } from 'react-notifications'
import { useParams } from 'react-router-dom'

//Components
import businesseservice from "../../../services/business_services"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminBusinessDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            business: null,
            users: [],
            purchase_history: [],
            buds: [],
            selectKey: null,
            order: 'asc',
            loading: true,
        }
    }

    componentDidMount = async () => {
        await this.loadBusiness()
    }

    loadBusiness = async () => {
        this.setState({ loading: true })
        const { id } = this.props.match.params
        const data = await businesseservice.businessDetails(id)
        this.setState({ business: data.business, buds: data.buds, purchase_history: data.purchase_history, users: data.users, loading: false })
    }

    reorderBuds = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let buds = this.state.buds
        if (order === 'asc')
            buds.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            buds.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ buds })
    }

    reorderPurchases = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let purchase_history = this.state.purchase_history
        if (order === 'asc')
            purchase_history.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            purchase_history.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ purchase_history })
    }

    reorderUsers = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let users = this.state.users
        if (order === 'asc')
            users.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            users.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ users })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/business'}>Business</span> {">"} {this.state.business && <span className="pointer hover" onClick={() => window.location.href = `/admin/business/details/${this.state.business.business_uuid}`}>{this.state.business.business_name}</span>}</h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">
                            Merchant details
                        </h4>
                        {this.state.loading ?
                            <p>Loading...</p>
                            :
                            <div className="selecter">
                                {this.state.business.logo && <img src={this.state.business.logo} style={{ height: "180px" }} alt="Merchant logo" />}
                                {this.state.business.logo_url && <img src={this.state.business.logo_url} style={{ height: "180px" }} alt="Merchant logo" />}
                                <h1>{this.state.business.business_name}</h1>
                                <p><a href={this.state.business.website} target="_blank" rel="noreferrer">{this.state.business.website}</a></p>
                                <p>
                                    <small>Address:</small> {this.state.business.address} <br />
                                    <small>City:</small> {this.state.business.city} <br />
                                    <small>Country:</small> {this.state.business.country} <br />
                                </p>
                                {this.state.business.description && this.state.business.description.en ?
                                    <p>
                                        <small><strong>FR</strong>: {this.state.business.description.fr} <br /></small>
                                        <small><strong>EN</strong>: {this.state.business.description.en}</small>
                                    </p>
                                    :
                                    <p><small style={{ color: "#FF0000" }}></small>No valid description</p>
                                }
                                <p>
                                    <small>Created {new Date(this.state.business.created_date).toLocaleDateString()}</small>
                                </p>
                                <hr />
                                <h2>{this.state.buds.length} buds</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="left" style={{ width: "40px" }}>#</th>
                                            <th style={{ width: "130px" }}>Visual</th>
                                            <th className="left" style={{ width: "140px" }}>Level</th>
                                            <th className="left" style={{ width: "200px" }}>Name</th>
                                            <th className="filter left" style={{ width: "95px" }} onClick={() => this.reorderBuds("nb_item")}>⥃ Nb cards</th>
                                            <th className="filter left" style={{ width: "130px" }} onClick={() => this.reorderBuds("nb_distributed")}>⥃ Nb distributed</th>
                                            <th style={{ width: "100px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.buds.map((bud, index) =>
                                            <tr key={`bud_${bud.bud_uuid}`}>
                                                <td>
                                                    <strong>{index + 1}</strong>
                                                </td>
                                                <td>
                                                    <div className="min_card" style={{ backgroundImage: `url('${bud.bud_info.img_url}')` }}>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className={`badge ${bud.bud_info.bud_level} relative`}>{bud.bud_info.bud_level}</div>
                                                </td>
                                                <td>
                                                    <strong className="left"><small>{bud.bud_info.bud_name}</small></strong>
                                                </td>
                                                <td>
                                                    <strong>{bud.nb_item}</strong>
                                                </td>
                                                <td>
                                                    <strong>{bud.nb_distributed}</strong>
                                                </td>
                                                <td>
                                                    <small style={{ cursor: "pointer" }}><a href={`/admin/buds/details/${bud.bud_uuid}`} target="_blank" rel="noreferrer"><strong>Bud details</strong></a></small>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <hr />
                                <h2>{this.state.users.length} users referenced</h2>
                                {this.state.users.length > 0 && <table>
                                    <thead>
                                        <tr>
                                            <th className="filter left" style={{ width: "240px" }} onClick={() => this.reorderUsers("firstname")}>⥃ Firstname</th>
                                            <th className="filter left" style={{ width: "240px" }} onClick={() => this.reorderUsers("lastname")}>⥃ Lastname</th>
                                            <th className="filter left" style={{ width: "280px" }} onClick={() => this.reorderUsers("email_address")}>⥃ Email</th>
                                            <th className="filter left" style={{ width: "140px" }} onClick={() => this.reorderUsers("last_login_date")}>⥃ Last seen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.users.map(player =>
                                            <tr key={`player_${player.player_uuid}`}>
                                                <td>
                                                    <strong className="left ml-10">{player.firstname}</strong>
                                                </td>
                                                <td>
                                                    <strong className="left ml-10">{player.lastname}</strong>
                                                </td>
                                                <td>
                                                    <small className="left">{player.email_address}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{new Date(player.last_login_date).toLocaleDateString()}</small>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>}
                                <hr />
                                <h2>{this.state.purchase_history.length} purchases referenced</h2>
                                {this.state.purchase_history.length > 0 && <table>
                                    <thead>
                                        <tr>
                                            <th className="filter left" style={{ width: "240px" }} onClick={() => this.reorderPurchases("purchase_type")}>⥃ Purchase type</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorderPurchases("quantity")}>⥃ Quantity</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorderPurchases("total_price")}>⥃ Total price</th>
                                            <th className="filter left" style={{ width: "140px" }}>Purchase date</th>
                                            <th className="filter left" style={{ width: "140px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.purchase_history.map(purchase =>
                                            <tr key={`purchase_${purchase.business_purchase_uuid}`}>
                                                <td>
                                                    <strong className="left ml-10">{purchase.purchase_type === 0 ? "Bud" : "XP"}</strong>
                                                </td>
                                                <td>
                                                    <small className="left">{purchase.quantity}</small>
                                                </td>
                                                <td>
                                                    <small className="left">
                                                        {purchase.total_price}Eur
                                                    </small>
                                                    {purchase.cancelled &&
                                                        <small className="left" style={{ color: "#FF0000" }}>
                                                            <br />
                                                            Cancelled
                                                        </small>
                                                    }
                                                </td>
                                                <td>
                                                    <small className="left">{new Date(purchase.created_date).toLocaleDateString()}</small>
                                                </td>
                                                <td>
                                                    {purchase.bud_uuid &&
                                                        <small style={{ cursor: "pointer" }}><a href={`/admin/buds/details/${purchase.bud_uuid}`} target="_blank" rel="noreferrer"><strong>Bud details</strong></a></small>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>}
                            </div>}
                    </div>
                </div>
                <NotificationContainer />
            </>
        )
    }
}

export default withRouter(AdminBusinessDetails)