import "./business.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { FaCheck, FaTimes } from "react-icons/fa"

//Components
import businesseservice from "../../../services/business_services"

class AdminBusiness extends Component {
    constructor(props) {
        super(props)
        this.state = {
            businesses: [],
            deactivated: false,
            registered: false,
            selectKey: null,
            order: 'asc',
            loading: true,
        }
    }

    componentDidMount = async () => {
        await this.loadBusinesses()
    }

    loadBusinesses = async () => {
        this.setState({ loading: true })
        const businesses = await businesseservice.businesses(this.state.deactivated, this.state.registered)
        this.setState({ businesses, loading: false })
    }

    switchDeactivated = async () => {
        this.setState({ deactivated: !this.state.deactivated }, () => this.loadBusinesses())
    }

    switchRegistered = async () => {
        this.setState({ registered: !this.state.registered }, () => this.loadBusinesses())
    }

    reorder = async (key) => {
        let order = 'asc'
        if (this.state.selectKey === key && this.state.order === 'asc')
            order = 'desc'
        this.setState({ selectKey: key, order })
        let businesses = this.state.businesses
        if (order === 'asc')
            businesses.sort((a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
        else
            businesses.sort((a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0))
        this.setState({ businesses })
    }

    activate = async (business_uuid) => {
        try {
            const data = await businesseservice.activateBusiness(business_uuid)
            NotificationManager.success(data.message)
            this.loadBusinesses()
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    deactivate = async (business_uuid) => {
        try {
            const data = await businesseservice.deactivateBusiness(business_uuid)
            NotificationManager.success(data.message)
            this.loadBusinesses()
        } catch (e) {
            NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/business'}>Business</span></h2>
                    <div className="selectcard mt-0">
                        <button className="button d-line" onClick={() => window.location.href = '/admin/business/activities'}>Activities</button>
                        <h4 className="left mb-0">
                            Browse {this.state.deactivated ? "DEACTIVATED" : "ACTIVE"} {this.state.registered ? "REGISTERED" : "REFERENCED"} businesses
                            {this.state.deactivated ?
                                <span className="switch" onClick={this.switchDeactivated}>Display active accounts</span>
                                :
                                <span className="switch" onClick={this.switchDeactivated}>Display deactivated accounts</span>
                            }
                            {this.state.registered ?
                                <span className="switch" onClick={this.switchRegistered}>Display only referenced accounts</span>
                                :
                                <span className="switch" onClick={this.switchRegistered}>Display only registered accounts</span>
                            }
                        </h4>
                        {this.state.loading ?
                            <p>Loading...</p>
                            :
                            <div className="selecter">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="left" style={{ width: "40px" }}>#</th>
                                            <th style={{ width: "130px" }}>Logo</th>
                                            <th className="filter left" style={{ width: "240px" }} onClick={() => this.reorder("business_name")}>⥃ Name</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("website")}>⥃ Website</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("city")}>⥃ City</th>
                                            <th className="filter left" style={{ width: "180px" }} onClick={() => this.reorder("country")}>⥃ Country</th>
                                            <th className="filter left" style={{ width: "180px" }}></th>
                                            <th className="filter left" style={{ width: "40px" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.businesses.map((business, index) =>
                                            <tr key={`business_${business.business_uuid}`}>
                                                <td>
                                                    <strong>{index + 1}</strong>
                                                </td>
                                                <td>
                                                    {business.logo && <div className="min_card" style={{ backgroundImage: `url('${business.logo}')` }}>
                                                    </div>}
                                                    {business.logo_url && <div className="min_card" style={{ backgroundImage: `url('${business.logo_url}')` }}>
                                                    </div>}
                                                </td>
                                                <td>
                                                    <strong className="left ml-10">{business.business_name}</strong>
                                                </td>
                                                <td>
                                                    <small className="left"><a href={business.website} target="_blank" rel="noreferrer">{business.website}</a></small>
                                                </td>
                                                <td>
                                                    <small className="left">{business.city}</small>
                                                </td>
                                                <td>
                                                    <small className="left">{business.country}</small>
                                                </td>
                                                <td>
                                                    <small style={{cursor: "pointer"}} onClick={() => window.location.href=`/admin/business/details/${business.business_uuid}`}><strong>+ Details</strong></small>
                                                </td>
                                                <td>
                                                    {this.state.deactivated ?
                                                        <div className="line_deactivate" onClick={() => this.activate(business.business_uuid)}> <FaCheck /> </div>
                                                        :
                                                        <div className="line_deactivate" onClick={() => this.deactivate(business.business_uuid)}> <FaTimes /> </div>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                </div>
                <NotificationContainer />
            </>
        )
    }
}

export default withTranslation()(AdminBusiness)