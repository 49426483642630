import "./stats.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

//Components
import AdminService from "../../../services/admin_services"

const d = new Date();


class AdminStats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected_action: 0,
            actions_info: {},
            period_average: {},
            stars_daily_average: {},
            stars_daily_stats: {},
            safes_daily_stats: {},
            safes_stats: {},
            end_date: new Date().toISOString().substring(0, 10),
            start_date: new Date(d.setMonth(new Date().getMonth() - 1)).toISOString().substring(0, 10),
            options_stars: null,
            series_star: null,
        }
    }

    componentDidMount = async () => {
        await this.loadStats()
    }

    loadStats = async () => {
        const stars = await AdminService.getStarsUsage(this.state.start_date, this.state.end_date, this.state.selected_action)
        const safes = await AdminService.getSafesUsage(this.state.start_date, this.state.end_date)
        const actions_by_player = [], nb_actions = [], nb_players = [], nb_stars = [], stars_by_action = [], stars_by_player = []
        Object.keys(stars.daily_stats).map(key => actions_by_player.push(stars.daily_stats[key].actions_by_player))
        Object.keys(stars.daily_stats).map(key => nb_actions.push(stars.daily_stats[key].nb_actions))
        Object.keys(stars.daily_stats).map(key => nb_players.push(stars.daily_stats[key].nb_players))
        Object.keys(stars.daily_stats).map(key => nb_stars.push(stars.daily_stats[key].nb_stars))
        Object.keys(stars.daily_stats).map(key => stars_by_action.push(stars.daily_stats[key].stars_by_action))
        Object.keys(stars.daily_stats).map(key => stars_by_player.push(stars.daily_stats[key].stars_by_player))
        this.setState({
            actions_info: stars.actions_info,
            period_average: stars.period_average,
            stars_daily_average: stars.daily_average,
            stars_daily_stats: stars.daily_stats,
            safes_daily_stats: safes.daily_stats,
            safes_stats: safes.safes_stats,
            options_stars: { chart: { id: "stars-bar" }, xaxis: { categories: Object.keys(stars.daily_stats) } },
            actions_by_player: [{ name: "stars", data: actions_by_player }],
            nb_actions: [{ name: "stars", data: nb_actions }],
            nb_players: [{ name: "stars", data: nb_players }],
            nb_stars: [{ name: "stars", data: nb_stars }],
            stars_by_action: [{ name: "stars", data: stars_by_action }],
            stars_by_player: [{ name: "stars", data: stars_by_player }],
        })
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/stats'}>Statistics</span></h2>
                    <div className="selectcard mt-0">
                        <div className="button" onClick={() => window.location.href = '/admin/stats/stars'}>
                            ⭐ Stars statistics
                        </div>
                        <div className="button" onClick={() => window.location.href = '/admin/stats/gems'}>
                            💎 Gems statistics
                        </div>
                        <div className="button" onClick={() => window.location.href = '/admin/stats/safes'}>
                            🔐 Safes statistics
                        </div>
                        <div className="button" onClick={() => window.location.href = '/admin/stats/games'}>
                            ⚔️ Game statistics
                        </div>
                        <div className="button" onClick={() => window.location.href = '/admin/stats/players'}>
                            🤾 Players statistics
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(AdminStats)