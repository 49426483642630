import "../buds.css"
import 'react-notifications/lib/notifications.css'
import React, { Component } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom'

//Components
import Footer from '../../../../components/footer'
import CardService from "../../../../services/card_services"

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />
    }
}

class AdminUpdateCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            processing: false,
            success: false,
            collection_uuid: null,
            name: null,
            description_fr: null,
            description_en: null,
        }
    }

    componentDidMount = async() => {
        const { id } = this.props.match.params
        const collections = await CardService.getCollections()
        for(let collection of collections){
            if(collection.collection_uuid === id)
                this.setState({name: collection.collection_name, description_fr: collection.description.fr, description_en: collection.description.en || collection.description, collection_uuid: id})
        }
    }

    update = async () => {
        if (!this.state.name || !this.state.description_fr || !this.state.description_en)
            NotificationManager.error('Missing information')
        else {
            try {
                this.setState({processing: true})
                const data = await CardService.updateCollection(this.state.collection_uuid, this.state.name, {en: this.state.description_en, fr: this.state.description_fr})
                NotificationManager.success(data.message)
                this.setState({processing: false, success: true})
            } catch (e) {
                this.setState({processing: false})
                NotificationManager.error(e.response && e.response.data ? e.response.data.message : e.message)
            }
        }
    }

    render() {
        return (
            <>
                <div className="admin">
                    <h2><span className="pointer hover" onClick={() => window.location.href = '/admin'}>Administration</span> {">"} <span className="pointer hover" onClick={() => window.location.href = '/admin/buds'}>Buds</span></h2>
                    <div className="selectcard mt-0">
                        <h4 className="left mb-0">Set all required information to update Collection</h4>
                        <input type="text" placeholder="Set Collection name" onChange={(e) => this.setState({name: e.target.value})} value={this.state.name}/>
                        <textarea  placeholder="Description EN" onChange={(e) => this.setState({description_en: e.target.value})} value={this.state.description_en}></textarea>
                        <textarea  placeholder="Description FR" onChange={(e) => this.setState({description_fr: e.target.value})} value={this.state.description_fr}></textarea>
                        {this.state.processing?
                            <p>Processing... Please wait</p>
                        :
                            !this.state.success? 
                                <button className="button" onClick={this.update}>Update collection</button>
                            :
                                <button className="button" onClick={() => window.location.href="/admin/buds"}>🡐 Back</button>
                        }
                    </div>
                </div>
                <NotificationContainer />
                
            </>
        )
    }
}

export default withRouter(AdminUpdateCollection)