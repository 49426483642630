import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import AdminHome from './pages/admin'
import AdminLogin from './pages/admin/login'
import AdminBuds from './pages/admin/buds'
import AdminCreateBud from './pages/admin/buds/create'
import AdminUpdateBud from './pages/admin/buds/update'
import AdminCreateCollection from './pages/admin/buds/collection/create'
import AdminUpdateCollection from './pages/admin/buds/collection/update'
import AdminAccounts from './pages/admin/accounts'
import AdminCreateAccount from './pages/admin/accounts/create'
import AdminUpdateAccount from './pages/admin/accounts/update'
import AdminBudDetails from './pages/admin/buds/details'
import AdminNewBudSale from './pages/admin/buds/sale'
import AdminUpdateBudSale from './pages/admin/buds/sale/update'
import AdminBusiness from './pages/admin/business'
import AdminBusinessDetails from './pages/admin/business/details'
import AdminSubscribers from './pages/admin/subscribers'
import AdminStats from './pages/admin/stats'
import AdminStatStars from './pages/admin/stats/stars'
import AdminStatGems from './pages/admin/stats/gems'
import AdminStatSafes from './pages/admin/stats/safes'
import AdminStatGames from './pages/admin/stats/games'
import AdminStatPlayers from './pages/admin/stats/players'
import AdminPlayers from './pages/admin/players'
import AdminBusinessActivities from './pages/admin/business/activities'
import AdminBots from './pages/admin/bots'
import AdminTrackers from './pages/admin/trackers'

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
          <BrowserRouter>
            <Routes>
              {/* ADMIN */}
              <Route path="/" element={<AdminLogin />} />
              <Route path="/admin" element={<AdminHome />} />
              <Route path="/admin/login" element={<AdminLogin />} />
              <Route path="/admin/buds" element={<AdminBuds />} />
              <Route path="/admin/buds/create" element={<AdminCreateBud />} />
              <Route path="/admin/buds/update/:id" element={<AdminUpdateBud {...this.props} />} />
              <Route path="/admin/buds/details/:id" element={<AdminBudDetails {...this.props} />} />
              <Route path="/admin/buds/sale/new/:id" element={<AdminNewBudSale {...this.props} />} />
              <Route path="/admin/buds/sale/update/:id/:sale_id" element={<AdminUpdateBudSale {...this.props} />} />
              <Route path="/admin/buds/collection/create" element={<AdminCreateCollection />} />
              <Route path="/admin/buds/collection/update/:id" element={<AdminUpdateCollection {...this.props} />} />
              <Route path="/admin/accounts" element={<AdminAccounts />} />
              <Route path="/admin/account/create" element={<AdminCreateAccount />} />
              <Route path="/admin/account/update/:id" element={<AdminUpdateAccount {...this.props} />} />
              <Route path="/admin/business" element={<AdminBusiness />} />
              <Route path="/admin/business/details/:id" element={<AdminBusinessDetails {...this.props} />} />
              <Route path="/admin/subscribers" element={<AdminSubscribers />} />
              <Route path="/admin/stats" element={<AdminStats />} />
              <Route path="/admin/stats/stars" element={<AdminStatStars />} />
              <Route path="/admin/stats/gems" element={<AdminStatGems />} />
              <Route path="/admin/stats/safes" element={<AdminStatSafes />} />
              <Route path="/admin/stats/games" element={<AdminStatGames />} />
              <Route path="/admin/stats/players" element={<AdminStatPlayers />} />
              <Route path="/admin/players" element={<AdminPlayers />} />
              <Route path="/admin/business/activities" element={<AdminBusinessActivities />} />
              <Route path="/admin/bots" element={<AdminBots />} />
              <Route path="/admin/trackers" element={<AdminTrackers />} />
              <Route path="*" element={<AdminLogin />} />
            </Routes>
          </BrowserRouter>
        )
    }
}

export default withTranslation()(App)