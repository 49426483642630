import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount = async () => {
    }

    render() {
        return (
            <footer>
                <div className="block">
                    <h1>Loyal<span className="buds">Buds</span></h1>
                    <h5 style={{ marginTop: "6px" }}>
                        <small>Le site Internet loyalbuds.io est la propriété de Codinsight SAS.</small>
                        <br />
                        Copyright @Codinsight. 2023.
                    </h5>
                </div>
                <div className="block">
                    <h5>
                        Editeur du Site
                        <br />
                        <small>L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€, immatriculée 907 943 088 au RCS de Montpellier.</small>
                        <br />
                        <small>N° TVA : FR18907943088</small>
                        <br />
                        <small>Email : contact@codinsight.com</small>
                        <br />
                        <small>Directeur de publication : M. Fabien BUCAMP.</small>
                    </h5>
                </div>
                <div className="block" style={{ border: "0" }}>
                    <h5>
                        Hébergeur du Site
                        <br />
                        <small>Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services:</small>
                        <br />
                        <small>Amazon Web Services Inc.</small>
                        <br />
                        <small>410 Terry Avenue North,</small>
                        <br />
                        <small>Seattle, WA 98109-5210, USA</small>
                    </h5>
                </div>
            </footer>
        )
    }
}

export default withTranslation()(Footer)