import api from './api';
import TokenService from './token_services';

class AdminService {
  async login(login, password, hcaptcha_passcode) {
    return await api
      .post('/admin/login', { login, password, hcaptcha_passcode, device: "" })
      .then(async (response) => {
        if (response.data.jwt_token) {
          await TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  async login2fa(login, password, twofa_token) {
    return await api
      .post('admin/login/2fa', { login, password, "2fa_token": twofa_token, device: "" })
      .then(async (response) => {
        if (response.data.jwt_token) {
          await TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  async getAdmins(deactivated = false) {
    return await api
      .get(`/admin?deactivated=${deactivated}`)
      .then(async (response) => {
        return response.data.admin_accounts;
      });
  }

  async createAdmin(password, email_address, firstname, lastname) {
    return await api
      .post(`/admin/create`, {password, email_address, firstname, lastname})
      .then(async (response) => {
        return response.data;
      });
  }

  async updateAdmin(email_address, firstname, lastname) {
    return await api
      .post(`/admin/update`, {email_address, firstname, lastname})
      .then(async (response) => {
        return response.data;
      });
  }

  async updateAdminPswd(password) {
    return await api
      .post(`/admin/update`, {password})
      .then(async (response) => {
        return response.data;
      });
  }

  async deactivateAdmin(admin_uuid) {
    return await api
      .post(`/admin/deactivate`, {admin_uuid})
      .then(async (response) => {
        return response.data;
      });
  }

  async activateAdmin(admin_uuid) {
    return await api
      .post(`/admin/reactivate`, {admin_uuid})
      .then(async (response) => {
        return response.data;
      });
  }

  async logout() {
    await TokenService.removeUser();
  }

  async getCurrentUser() {
    return await TokenService.getUser();
  }

  async getStarsUsage(start_date, end_date, action_type = 0) {
    return await api
      .get(`/admin/stats/assets?start_date=${start_date}&end_date=${end_date}&action_type=${action_type}&asset=0`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getGemsUsage(start_date, end_date, action_type = 0) {
    return await api
      .get(`/admin/stats/assets?start_date=${start_date}&end_date=${end_date}&action_type=${action_type}&asset=1`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getSafesUsage(start_date, end_date) {
    return await api
      .get(`/admin/stats/safes?start_date=${start_date}&end_date=${end_date}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getGamesStats(start_date, end_date) {
    return await api
      .get(`/admin/stats/games?start_date=${start_date}&end_date=${end_date}`)
      .then(async (response) => {
        return response.data.games_stats;
      });
  }

  async getPlayersStats(start_date, end_date) {
    return await api
      .get(`/admin/stats/players?start_date=${start_date}&end_date=${end_date}`)
      .then(async (response) => {
        return response.data;
      });
  }

  async getBots() {
    return await api
      .get(`/admin/bots`)
      .then(async (response) => {
        return response.data.bots;
      });
  }

  async updateBotsCard(card_uuid, new_scores) {
    return await api
      .post(`/admin/bots/cards`, {card_uuid, new_scores})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async updateBotsXP(card_uuid, new_xp) {
    return await api
      .post(`/admin/bots/cards`, {card_uuid, new_xp})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async createBot(bot_level, username) {
    return await api
      .post(`/admin/bots`, {bot_level, username})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async getTrackers() {
    return await api
      .get(`/admin/marketing/tracker`)
      .then(async (response) => {
        return response.data.trackers;
      });
  }

  async createTracker(title) {
    return await api
      .post(`/admin/marketing/tracker`, {title})
      .then(async (response) => {
        return response.data.status;
      });
  }

  async resetBots() {
    return await api
      .get(`/admin/bots/reset`)
      .then(async (response) => {
        return response.data.status;
      });
  }

  async updateBotPower(player_uuid) {
    return await api
      .post(`/admin/bots/power`, {player_uuid})
      .then(async (response) => {
        return response.data.status;
      });
  }
}
// eslint-disable-next-line
export default new AdminService();
